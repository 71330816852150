// @import url('../abstracts/_abstracts.scss');
:root {
  --color-primary: #EF4A49;
  --color-primaryAlternative: #F26E6D;
  --color-primaryTransparent: rgba(239, 74, 73, 0.8);
  --color-secondary: #3E174C;
  --color-secondaryTransparent: rgba(62, 23, 76, 0.71);
  --color-terciary: #F1ECDE;
  --color-terciaryTransparent: rgba(241, 236, 222, 0.9);
  --logo-app: url('./../../images/logo_VLC.png');
  --logobg-color: #F1ECDE;
  --img-wine-user: url('../../images/wines-default/wine-default.webp');
}

$color-primary: var(--color-primary);
$color-primaryAlternative: var(--color-primaryAlternative);
$color-primaryTransparent: var(--color-primaryTransparent);
$color-secondary: var(--color-secondary);
$color-secondaryTransparent: var(--color-secondaryTransparent);
$color-terciary: var(--color-terciary);
$color-terciaryTransparent: var(--color-terciaryTransparent);
$logo-app: var(--logo-app);
$img-wine-user: var(--img-wine-user);
$logobg-color: var(--logobg-color);
$color-backgroundW: white;
$color-black: #222222;
$color-white: white;
$color-bgTransparent: rgba(52,31,17,0.2);
$color-green: #79B97D;
$header-height-type: 10rem;
$header-height-item: 18rem;
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700");
// * {
//     -webkit-user-select: none;
//     -moz-user-select: none;
//     -ms-user-select: none;
//     user-select: none;
// }

body {
  font-family: 'Montserrat', sans-serif;
  overflow: overlay;
}

body,
html {
  height: 100% !important;
}

*::-webkit-scrollbar {
  width: 0;
  /* width of the entire scrollbar */
}

*::-webkit-scrollbar-track {
  background: transparent
    /* color of the tracking area */
}

*::-webkit-scrollbar-thumb {
  background: transparent;
  /* color of the scroll thumb */
  border-radius: 0px;
  /* roundness of the scroll thumb */
  background: transparent;
  /* creates padding around scroll thumb */
}


/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


/* Firefox */

input[type=number] {
  -moz-appearance: textfield;
}

//---------------------------------Preloaders init---------------------//
//---------------------------------PR Customer init---------------------//
.preloaderCustomizer {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.textPrelaoder {
  font-size: .8rem;
}

.text-color {
  color: $color-primary !important;
}

// .menuLoading {
//   display: flex;
//   gap: .2rem;
//   justify-content: center;
//   align-items: center;
//   position: absolute;
//   bottom: 20px;
//   margin: 0 auto;
//   left: 0;
// }

.fadeInSelector {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  z-index: 999 !important;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

/* --------------------Animation Fade In init-------------------- */

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


/* --------------------Animation Fade In End-------------------- */


/* --------------------Animation Background init-------------------- */

.bgPreloader {
  /* background: linear-gradient(-45deg, #EF4A49,   #3E174C); */
  background: linear-gradient(-45deg, #EF4A49, #F26E6D);
  /* background-color: #EF4A49; */
  background-size: 400% 400%;
  animation: gradient 5s ease infinite;
}

//


//---------------------------------PR Customer end---------------------//
//---------------------------------PR Redux init---------------------//

/* //-----------------Preloader Init ------------- */
/* //-----------------Borrar a futuro cuando cambiemos por Skeleton ------------- */
.spinnerCustomizer {
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin: 0 !important;
}

/* Clases para spinner SOLO con fondo blanco y color restaurant */

.spinnerCustomizerH {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.colorPrimary {
  background-color: $color-primary;
}


/* Clases para spinner SOLO con fondo blanco y color vineria */



.colorSecondary {
  background-color: $color-secondary;
}


/* Clase para spinner menu restaurant  */

.spinner-growmC {
  width: .3rem !important;
  height: .3rem !important;
}

.colorTerciary {
  background-color: $color-terciary;
}

.spinner-growmCM {
  width: .8rem !important;
  height: .8rem !important;
}


/* --------------------Spinner Dashboard end-------------------- */

//---------------------------------PR Redux end---------------------//
//---------------------------------Preloaders end---------------------//
//---------------------------------Menu init---------------------//
.container-fluidMenu {
  padding: 0;
  justify-content: center;
  //  align-items: center;
  display: flex;
  flex-direction: column;
  background: $color-backgroundW;
}



//----------------------Header end---------------------//


//---------------------Filters inside categories init -----------//
.containerFiltersMenu {
  display: flex;
  justify-content: space-between;
  overflow: auto;
  white-space: nowrap;
  width: 100%;
  height: 6.3rem;
  padding: 0.5rem 1rem 1rem 1rem;
  margin-top: -2rem;
  background-color: #fbfbfb;
  width: 95%;
  margin: 0 auto;
  overflow-y: hidden;
  // transform: translate(0px, -27px);
  padding-top: 1rem;
  padding-bottom: 6rem;

  .disableFiltersMenu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    // gap: 0.4rem;
    padding: 0.5rem;
    width: 5rem;
    height: 5rem;

    &:focus {
      border-color: none;
      outline: 0;
    }

    img {
      width: 1.7rem;
    }

    .fontFilterMenu {
      font-size: 0.8rem;
      color: #414141;
      font-weight: 600;
      margin-bottom: 0rem;
    }
  }



  .iconTextFiltersMenu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    // gap: 0.4rem;
    padding: 0.5rem;
    width: 5rem;
    height: 5rem;

    &:hover {
      background-color: white;
      border: none !important;
      border-radius: 0.625rem;
      box-shadow: 10px 11px 15px -5px rgb(64 64 64 / 28%) !important;
    }

    &:focus {
      border-color: none;
      border-radius: 0.625rem;
      outline: 0;
      box-shadow: 10px 11px 15px -5px rgb(64 64 64 / 28%) !important;

    }

    &:visited {
      border-color: none;
      border-radius: 0.625rem;
      outline: 0;
      box-shadow: 10px 11px 15px -5px rgb(64 64 64 / 28%) !important;
    }

    img {
      width: 1.7rem;
    }

    .fontFilterMenu {
      font-size: 0.8rem;
      color: #414141;
      font-weight: 600;
      margin-bottom: 0rem;
    }
  }
}

.filterCoomingSoon {
  opacity: 0.4;
}

.topPaneFilters {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

.containerPriceFilter {
  height: 4rem;
  padding: 0.5rem 1.5rem;
  background-color: #fbfbfb;
  width: 95%;
  margin: 0 auto;
  margin-bottom: 1rem;
  border-radius: 0 0 10px 10px;
  -webkit-border-radius: 0 0 10px 10px;
  -moz-border-radius: 0 0 10px 10px;
  -ms-border-radius: 0 0 10px 10px;
  -o-border-radius: 0 0 10px 10px;
}

.containerFiltersChips {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  height: fit-content;
  padding: 0rem 1rem 0rem 1rem;
  gap: 0.5rem;
  background-color: #fbfbfb;
  width: 95%;
  margin: 0 auto;
}

.containerInputFiltersMenu {
  display: flex;
  border: 0.1rem solid #d4d4d4;
  border-radius: 0.75rem;
  // margin-bottom: 0.6rem;
  padding: 0rem 0.5rem;
  width: 100%;
  height: fit-content;

  &:focus {
    border-color: gray;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
  }
}

.filtersAndImput {
  transform: translate(0px, -27px);
  display: flex;
  flex-direction: column;

}

.chipsWineFilter {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.mat-chipsChips {
  width: fit-content;

  img {
    height: fit-content;
    color: #878379 !important;
  }
}


.mat-option {
  font-family: Montserrat !important;
  font-size: 14px;
}

//---------------------Filters inside categories end -----------//

// ------------------- Customer header init -----------------------------//

.containerBtn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.containerHeaderCustHeader {
  height: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  .colCustomHeader {
    background: rgb(250, 250, 250);
    //background: rgb(250, 250, 250, 0.6);
    padding: 0.6rem;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    display: flex !important;
    align-items: center !important;
    height: 23px;

    mat-icon,
    img {
      opacity: 0.7;
      color: #525252;
      width: 17px !important;
      height: 17px !important;
    }
  }



  .colCustomHeader-checkout::after {
    content: " ";
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    background-color: $color-green;
    height: 14px;
    width: 14px;
    position: absolute;
    top: 9px;
    right: 10px;
  }



}

// ------------------Modal Nav header ---------------------

.container-buttons-currency {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: .5rem;
}

.button-currency-active:checked {
  width: 88px;
  height: 37px;
  background: #E5EEE6;
  border-radius: 5px;
  border: none;
  font-size: 13px;

}


// ------------------Modal Nav header ---------------------


.formInputImageEnvel {
  &::placeholder {
    padding-left: 30px;
    background-image: url('/../../assets/images/adm-panel/envelop.svg');
    background-size: 17px 17px;
    background-repeat: no-repeat;
    background-position: left center;
    color: #BABABA;
  }
}



// ------------------- Customer header end -----------------------------//

// ------------------- Card food init ----------------------------------//
.font-card-food {
  width: 90% !important;
  border-radius: 1rem !important;
  // margin: 0rem;

  h4 {
    font-size: 0.85rem;
    font-weight: 700;
    line-height: 0.9rem;
    color: #414141;
    margin: 0rem;
  }

  h6 {
    // font-family: 'Montserrat';
    font-size: 0.8rem;
    line-height: 0.9rem;
    color: #414141;
    margin: 0rem;
  }

  p {
    font-size: 0.75rem;
    line-height: 0.85rem;
    color: #414141;
    margin: 0rem;
  }

  .food-green-card {
    color: #4ca552;
  }
}

.cardBodyFoodMenu {
  // background-color: #F9F8F8 !important;

  p {
    font-size: 0.75rem;
    line-height: 0.85rem;
    color: #414141;
    margin: 0rem;
  }
}

.container-food {
  position: relative;
  bottom: 5.5rem;
  z-index: 200;
  width: 6rem;
  height: 2rem;
  color: $color-white;
  background: $color-green;
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px 0 0 5px;
  padding-left: .3rem;
  margin-left: auto;
  // -webkit-border-radius: 5px 0 0 5px;
  // -moz-border-radius: 5px 0 0 5px;
  // -ms-border-radius: 5px 0 0 5px;
  // -o-border-radius: 5px 0 0 5px;
  // -webkit-filter: drop-shadow(0px 1px 6px $color-green);
}


.food-container {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 7rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;

  // max-width: 50rem;
  width: 100%;
  // margin: 0 auto;
  // border-radius: 10px;
  // margin-bottom: 5rem;
}

// ------------------- Card food end ----------------------------------//

// ------------------ Card others drinks init -------------------------------//

.cardDrinksMenu {
  display: flex;
  flex-direction: row;
  width: 100% !important;
  border-radius: 1rem !important;
  // height: 12rem;
  margin-bottom: 2rem;

  .infoDrinksMenu {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
    gap: 0.3rem;
    margin-left: 1.5rem;
    max-width: 160px;

    h4 {
      font-size: 0.95rem;
      font-weight: 700;
      line-height: 1rem;
      color: #414141;
      margin: 0rem;
    }

    h6 {
      // font-family: 'Montserrat';
      font-size: 0.75rem;
      line-height: 0.9rem;
      color: #4ca552;
      margin: 0rem;
    }

    p {
      font-size: 0.8rem;
      line-height: 0.85rem;
      color: #414141;
      margin: 0rem;
    }
  }

  .containerImgDrinksMenu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    // background-image: linear-gradient(to top, #e8e3d4, #fffbf1);
    background-color: #f1ecde;
    flex-grow: 2;
    // border-radius: 1rem !important;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;

    .figureotherDrinks {
      padding: 0;
      width: 3rem;
      transform: translate(-7px, 15px);
      //-webkit-transform: translate(15px, -31px);
      //-moz-transform: translate(15px, -31px);
      //-ms-transform: translate(15px, -31px);
      //-o-transform: translate(15px, -31px);

      -webkit-transform: translate(-7px, 15px);
      -moz-transform: translate(-7px, 15px);
      -ms-transform: translate(-7px, 15px);
      -o-transform: translate(-7px, 15px);

      img {
        width: 100%;
        height: 100%;
      }
    }

    p {
      font-size: 0.6rem;
      text-align: center;
      color: #414141;
    }
  }
}


// .font-card-other-drinks {
//   width: 90% !important;
//   border-radius: 1rem !important;
//   height: 10rem;
//   // margin: 0rem;

//   .food-green-card {
//     color: #4ca552;
//   }
// }



// ------------------ CArd others drinks end -------------------------------//



// .wine-container {
//   background-size: cover;
//   background-position: center;
//   background-repeat: no-repeat;
//   height: 9rem;
//   border-top-left-radius: 1rem;
//   border-top-right-radius: 1rem;
//   width: 100%;
// }



.btArrowBack {
  position: absolute;
  background: rgba(180, 180, 180, 0.5);
  backdrop-filter: blur(50px);
  border-radius: 15px;
  padding: .3rem .8rem;
  z-index: 210 !important;
  top: 1rem;
  left: 1rem;
}


.input-header {
  outline: none;
  border: none;
  width: 100%;
  border-radius: 10px;
  height: 3rem;
  box-shadow: 0px 2px 10px rgba(112, 112, 112, 0.25);
  padding: 1rem;
  padding-left: 2rem;
  z-index: 100;
}




.img-header {
  position: absolute;
  left: 1.8rem;
  z-index: 250;
}

.container-404wine {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //padding: 2rem;
  //margin-top: 3rem;
  transform: translateY(-51px);
  -webkit-transform: translateY(-51px);
  -moz-transform: translateY(-51px);
  -ms-transform: translateY(-51px);
  -o-transform: translateY(-51px);
}


.text-404P {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #434343;
  padding: 0;
  margin: 0;
}

.title-404wine {
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  text-align: center;
  color: #A1A1A1;
}



.wine-container {
  //background: url("../../images/deleteFuture/lasperdices.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 17rem;
  max-width: 50rem;
  width: 95%;
  margin: 0 auto;
  border-radius: 10px;
  margin-bottom: 5rem;
}


.info-wine {
  padding-top: 4rem;
  width: 65%;
  margin-left: -1rem;

  p {
    text-align: left;
    padding: 0;
    margin: 0;
  }

  h3,
  h4 {
    text-align: left;
    padding-top: 0.5rem;
    margin: 0;
  }
}


// .wine {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   position: relative;
// top: 1rem;
//   left: 0;
//   padding: 0 1rem 0 1rem;
// }

.wineMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  top: -6rem;
  left: 0;
  padding: 0 1rem 0 1rem;

}

.wine-classic {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 1rem;
  left: 0;
}




.container-classic {
  background: rgb(233, 232, 232);
  background-size: cover;
  // height: 7rem;
  // max-width: 50rem;
  // width: 95%;
  margin: 0 auto;
  border-radius: 10px;
  margin-bottom: 1rem;
  display: grid;
  grid-template-columns: 1.6fr 0.4fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: ". .";
  margin: .4rem;
}

.container-add-classic {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.container-add-wine-classic {
  background: rgba(191, 223, 179, 0.5);
  // #4ca552
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  width: 90%;
  height: 90%;
  margin: 0 auto;
  padding-left: .5rem;
}

.wine-info-classic {
  display: grid;
  grid-template-columns: 1.6fr 0.4fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: ". .";
  gap: 1rem;
  width: 100%;
  padding: 1rem;
}




.wine-img-skeleton {
  padding: 0;
  height: auto;
  width: 5.8rem;
  // transform: translate(15px, -39px);
  transform: translate(10px, -15px);
  border-radius: 10px;

  img {
    // width: 100%;
    // height: 100%;
    width: 85%;
  }
}




.wine-name-classic,
.wine-winemaker-classic,
.wine-origin-classic {
  padding: 0;
  margin: 0;
}

.wine-name {
  font-weight: 600;
  font-size: 0.9rem;
  color: #222222;
  padding-top: 0.2rem;
  text-align: left;
  width: 100%;

}


.wine-price {
  font-weight: 700;
  font-size: .8rem;
  line-height: 20px;
  color: $color-black;
  letter-spacing: 0.0125em;
  padding-top: .2rem;
  text-align: end;
}




.item-info-name {
  font-style: normal;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 27px;
  letter-spacing: 0.0125em;
  color: $color-black;
  margin-bottom: .5rem;
  text-transform: uppercase;
  margin-bottom: .5rem;
}

.category-type-container {
  padding: 1rem;
  padding-top: 0;
  // margin-top: 1rem;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
}


.category-name {
  color: $color-white;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.0125em;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}



.header-menu {
  object-fit: cover;
  height: 10rem;
  width: 100vw;
  background: white;
}

.header-menu-seeker {
  object-fit: cover;
  height: 6rem;
  width: 100vw;
  background: white;
}

//-----------------View checkout Init -------------

.inputG {
  border-radius: 50px;
  width: 100%;
  border: 1px solid #424242;
  // height: 3em ;
  background: transparent;
}

.orderCompAlertCloseResto {
  display: flex;
  justify-content: center;
  color: $color-primary;
  padding: 0rem 2rem;
  font-size: 0.7rem;
  line-height: 0.8rem;
  gap: 0.5rem;
  margin-top: 3rem;
}




@media (max-width: 576px) {

  .item-info-name {
    font-size: 1rem;
  }

}


// --------------------- Media queries -------------------------------

@media (min-width: 982px) {
  // @media (min-width: 1200px) {


  .backHeaderCustHeader,
  .fixed-top-customer,
  // .header-degrade,
  .title-container,
  .title-order {
    // width: 60%;
    width: 981px !important;

  }

  .container-fluidMenu {
    // width: 60%;
    width: 981px;
    margin-right: auto;
    margin-left: auto;
  }

  .header-menu {
    width: 100%;
  }

  .container-checkout {
    padding: 0rem;
  }
}
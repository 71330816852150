///////////////////////////
// Nav Profile
///////////////////////////
.nav-label {
    margin: 10px 30px 0;
    padding: 1.5625rem 0 10px;
    text-transform: uppercase;
    font-size: 0.75rem;
    letter-spacing: 0.05rem;
    border-top: 1px solid $l-border;
	color:$dusty-gray;
	
    @at-root [data-theme-version="dark"] & {
        border-color: $d-border;
    }
    &.first {
        border: 0px;
        margin-top: 0px;
    }
}

.nav-badge {
    position: absolute;
    right: 2.8125rem;
    top: 0.625rem;
}

.content-body {
    margin-left: 21.563rem;
    z-index: 0;
    transition: all .2s ease;
    @include custommq($max: 1350px) {
        margin-left: 17rem;
    }
}
// Bell //
.bell img{
	-webkit-animation: ring 8s .7s ease-in-out infinite;
	-webkit-transform-origin: 50% 4px;
	-moz-animation: ring 8s .7s ease-in-out infinite;
	-moz-transform-origin: 50% 4px;
	animation: ring 8s .7s ease-in-out infinite;
}
// bell //
@-webkit-keyframes ring {
  0% { -webkit-transform: rotateZ(0); }
  1% { -webkit-transform: rotateZ(30deg); }
  3% { -webkit-transform: rotateZ(-28deg); }
  5% { -webkit-transform: rotateZ(34deg); }
  7% { -webkit-transform: rotateZ(-32deg); }
  9% { -webkit-transform: rotateZ(30deg); }
  11% { -webkit-transform: rotateZ(-28deg); }
  13% { -webkit-transform: rotateZ(26deg); }
  15% { -webkit-transform: rotateZ(-24deg); }
  17% { -webkit-transform: rotateZ(22deg); }
  19% { -webkit-transform: rotateZ(-20deg); }
  21% { -webkit-transform: rotateZ(18deg); }
  23% { -webkit-transform: rotateZ(-16deg); }
  25% { -webkit-transform: rotateZ(14deg); }
  27% { -webkit-transform: rotateZ(-12deg); }
  29% { -webkit-transform: rotateZ(10deg); }
  31% { -webkit-transform: rotateZ(-8deg); }
  33% { -webkit-transform: rotateZ(6deg); }
  35% { -webkit-transform: rotateZ(-4deg); }
  37% { -webkit-transform: rotateZ(2deg); }
  39% { -webkit-transform: rotateZ(-1deg); }
  41% { -webkit-transform: rotateZ(1deg); }
  43% { -webkit-transform: rotateZ(0); }
  100% { -webkit-transform: rotateZ(0); }
}
@-moz-keyframes ring {
  0% { -moz-transform: rotate(0); }
  1% { -moz-transform: rotate(30deg); }
  3% { -moz-transform: rotate(-28deg); }
  5% { -moz-transform: rotate(34deg); }
  7% { -moz-transform: rotate(-32deg); }
  9% { -moz-transform: rotate(30deg); }
  11% { -moz-transform: rotate(-28deg); }
  13% { -moz-transform: rotate(26deg); }
  15% { -moz-transform: rotate(-24deg); }
  17% { -moz-transform: rotate(22deg); }
  19% { -moz-transform: rotate(-20deg); }
  21% { -moz-transform: rotate(18deg); }
  23% { -moz-transform: rotate(-16deg); }
  25% { -moz-transform: rotate(14deg); }
  27% { -moz-transform: rotate(-12deg); }
  29% { -moz-transform: rotate(10deg); }
  31% { -moz-transform: rotate(-8deg); }
  33% { -moz-transform: rotate(6deg); }
  35% { -moz-transform: rotate(-4deg); }
  37% { -moz-transform: rotate(2deg); }
  39% { -moz-transform: rotate(-1deg); }
  41% { -moz-transform: rotate(1deg); }
  43% { -moz-transform: rotate(0); }
  100% { -moz-transform: rotate(0); }
}
@keyframes ring {
  0% { transform: rotate(0); }
  1% { transform: rotate(30deg); }
  3% { transform: rotate(-28deg); }
  5% { transform: rotate(34deg); }
  7% { transform: rotate(-32deg); }
  9% { transform: rotate(30deg); }
  11% { transform: rotate(-28deg); }
  13% { transform: rotate(26deg); }
  15% { transform: rotate(-24deg); }
  17% { transform: rotate(22deg); }
  19% { transform: rotate(-20deg); }
  21% { transform: rotate(18deg); }
  23% { transform: rotate(-16deg); }
  25% { transform: rotate(14deg); }
  27% { transform: rotate(-12deg); }
  29% { transform: rotate(10deg); }
  31% { transform: rotate(-8deg); }
  33% { transform: rotate(6deg); }
  35% { transform: rotate(-4deg); }
  37% { transform: rotate(2deg); }
  39% { transform: rotate(-1deg); }
  41% { transform: rotate(1deg); }
  43% { transform: rotate(0); }
  100% { transform: rotate(0); }
}

// 38px + 60px
///////////////
// Sidebar
//////////////
.deznav {
    width: 21.563rem;
    padding-bottom: 0;
    height: calc(100% - 120px);
    position: absolute;
    top: 7.5rem;
    padding-top: 0;
    z-index: 4;
    background-color: $white;
    // box-shadow: 0px 0px 10px rgba(120, 130, 140, 0.13);
    transition: all .2s ease;
    // border-top-left-radius: 5px;
    // border-top-right-radius: 5px;
	box-shadow: 0px 15px 30px 0px rgba(201, 19, 19, 0.02);
	
    @include respond('tab-land') {
        background-color: $white;
		top: 5rem;
		height: calc(100% - 80px);
    }
	@include respond('phone-land') {
         width: 18rem;
    }
    // @include respond('big-desktop') {
    //     left: 6rem;
    // }
    .deznav-scroll {
        position: relative;
        height: 100%;
        
		
        // overflow: visible!important;
        // border-top-left-radius: 5px;
        // border-top-right-radius: 5px;
    }
    @include custommq($max: 1350px) {
        width: 17rem;
    }
    ul {
        padding: 0;
        margin: 0;
        list-style: none;
    }
    .metismenu {
        display: flex;
        flex-direction: column;
		padding-top: 15px;
			
		
        &.fixed {
            position: fixed;
            top: 0;
            width: 100%;
            left: 0;
        }
        &>li {
            display: flex;
            flex-direction: column;
            a {
				
				
                &>i {
                    //icon will get color from parent "a"
                    font-size: 1.7rem;
                    display: inline-block;
                    vertical-align: middle;
                    position: relative;
                    top: 0;
					height:65px;
					width:65px;
					text-align:center;
					margin-right: 10px;
					border-radius: 2px;
					line-height:65px;
					border-radius:2px;
						
					@at-root [data-sidebar-style="compact"] & {
						display:block;
						padding:0;
						background:rgba($primary,0.2);
						color:$primary;
						width: 60px;
						height: 60px;
						border-radius: 0px;
						line-height: 60px;
						margin-left: auto;
						margin-right: auto;
						margin-bottom: 5px;
						&[data-theme-version="dark"]{
							color:$white;
						}
						
					}
					@include custommq($max:1350px) {
						height:50px;
						line-height:50px;
						width:50px;
						font-size: 24px;
						padding: 0;
						color: #969BA0;
					}
                }
            }
            &>a {
				font-weight: 400;
				display: inline-block;
				font-size: 15px;
				color: #969BA0;		
				i{
					color: #969BA0;
				}
				svg {
					max-width: 24px;
					max-height: 24px;
					height: 100%;
					margin-right:5px;
					margin-top: -3px;
					color: $primary;
				}
				g [fill] {
					fill: #8088a1;
				}
            }
            &:hover,
			&:focus {
				
                &>a {
                  //  background-color: lighten($color: $primary, $amount: 40%);
                    color: $primary;
					g [fill] {
						fill: $primary;
					}
                    // &::after {
                    //     border-color: blue;
                    // }
                }
            }
            &.mm-active {
				&>a {
					
					color:$black;
					font-weight: 600;
					box-shadow:none;
					i{
						color: $white;
						font-weight:100;
						box-shadow:0px 8px 10px rgba($primary,0.3);
						background:$primary;
					}
					g [fill] {
						fill: $primary;
					}
					
				}
				@at-root [data-sidebar-style="compact"] & {
					&>a{
						
						i{
							background:$primary;
							color:$white;
							box-shadow:0 12px 15px 0 rgba($primary, 0.13);
						}
					}
				}
            }
        }
        li {
            position: relative;
        }
		
        //one step dropdown
        ul {
            //background-color: darken($color: $white, $amount: 2%);
            transition: all .2s ease-in-out;
			position:relative;
			z-index: 1;
			padding: 0.5rem 0;
				
            a {
				padding-top: .5rem;
				padding-bottom: .5rem;
				position: relative;
				font-size: 15px;
				padding-left: 6.3rem;
				@include custommq($max: 1350px) {
					padding-left: 4.6rem;
				}
				@include respond('phone-land') {
					padding-left: 3.5rem;
					font-size: 14px;
				}
                &:hover,
                &:focus,
                &.mm-active {
                    text-decoration: none;
                    color: $primary;
                }
            }
            
        }
        a {
            position: relative;
            display: block;
            padding: 0.625rem 1.875rem;
            outline-width: 0;
            color: rgba($color: $body-color, $alpha: 1);
            text-decoration: none;
			@include respond('phone-land') {
				padding: 0.625rem 1.25rem;
			}
        }
        .has-arrow {
            &:after {
                width:  .5rem;
                height: .5rem;
                right: 1.875rem;
                top: 48%;
                border-color: inherit;
                -webkit-transform: rotate(-225deg) translateY(-50%);
                transform: rotate(-225deg) translateY(-50%);
				
            }
        }
        .has-arrow[aria-expanded=true]:after,
        .mm-active>.has-arrow:after {
            -webkit-transform: rotate(-135deg) translateY(-50%);
            transform: rotate(-135deg) translateY(-50%);
        }
    }
}

// .metismenu .mm-active>.has-arrow:after, .metismenu .has-arrow[aria-expanded=true]:after {
//     transform: rotate(45deg) translateY(-50%);
// }

.nav-header {
	@include custommq($max:1023px){
		width: 5rem;
	}
}
@media (max-width:767px) {

	.brand-title {
		display: none;
	}
    .footer {
        padding-left: 0;
    }
    .deznav {
        left: 0;
		top: 5rem;
    }
}
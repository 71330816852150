$borders: (
  "right",
  "left",
  "top",
  "bottom",
  ""
);


$theme_backgrounds: (
  "color_1": $color_pallate_1,
  "color_2": $color_pallate_2,
  "color_3": $color_pallate_3,
  "color_4": $color_pallate_4,
  "color_5": $color_pallate_5,
  "color_6": $color_pallate_6,
  "color_7": $color_pallate_7,
  "color_8": $color_pallate_8,
  "color_9": $color_pallate_9,
  "color_10": $color_pallate_10,
  "color_11": $color_pallate_11,
  "color_12": $color_pallate_12,
  "color_13": $color_pallate_13,
  "color_14": $color_pallate_14,
  "color_15": $color_pallate_15,
  "color_16": $color_pallate_16,
  "color_17": $color_pallate_17,
  "color_18": $color_pallate_18,
  "color_19": $color_pallate_19
);
/*
    Table of Contents

    Name                 : uena
    Author               : DexignZone
    Author Portfolio     : https://themeforest.net/user/dexignzone/portfolio



    Table of Sass Contents

    1. Customer

    2. Abstracts
        _bs-custom
        _inheritance
        _maps
        _mixin
        _variable

    3. bootstrap
        -Here goes all official bootstrap scss files

    4. Global/Base
        _color
        _custom-grid
        _fonts
        _helper
        _reset

    5. layout
        +footer
        +header
        +rtl
        +sidebar
        +tables
        +theme
        +version-dark

    6. Conponents
        +app
        +charts
        +forms
        +map
        +uc
        +ui
        +widget

    7. Page
        _index-1
        _page-error
        _page-register
        _page-signin
        _page-user-lock
        _page-user-lock

*/

// @use "./customer/" as cus;

////////////////////
// Abstracts
////////////////////
@import "./abstracts/abstracts";

//bootstrap library scss files
@import "./../vendor/bootstrap/scss/bootstrap";

////////////////////
// Global
////////////////////
// ------------Se puede comentar con cambios minimos
@import "./base/base";


// @import "./preloader";

////////////////////
// Components
////////////////////
@import "./layout/layout";

////////////////////
// Components
////////////////////
@import "./components/components";

////////////////////
// All pages
////////////////////
// @import "./pages/pages";
@import "./pages/homepage";

////////////////////
// RTL
////////////////////
// @import "./layout/rtl/rtl";

////////////////////
// Version Color
////////////////////
// @import "./layout/version-dark/main";

////////////////////
// Animations
////////////////////
@import "../../node_modules/animate.css/animate.css";

@import "./customer/";
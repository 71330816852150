////////////////////
// Abstracts
////////////////////
@import "./abstracts/abstracts";
.event-chart {
    margin: -10px 0px;
}

.default-select {
    &.style-1 {
        border: 0;
        font-size: 12px;
        padding: 5px;
        option {
            background: $white;
            box-shadow: 0 10px 40px 0 rgba(32, 28, 69, 0.1);
        }
    }
}

.event-table {
    border-radius: $radius;
    thead th {
        color: $black;
        font-size: 18px;
        white-space: nowrap;
        font-weight: 600;
        padding: 20px 10px;
    }
    tbody td {
        font-size: 14px;
        padding: 20px 10px;
    }
    @include respond ('laptop') {
        thead th {
            color: $black;
            font-size: 15px;
            white-space: nowrap;
            font-weight: 600;
        }
        tbody td {
            padding: 12px 15px;
        }
    }
}

.dropdown-no-icon {
    .dropdown-toggle:after {
        content: none;
    }
}

.dashboard-select {
    font-size: 16px;
    .mat-form-field-wrapper,
    .mat-form-field-appearance-fill .mat-form-field-flex {
        padding: 0;
        background: transparent;
    }
    .mat-form-field-underline {
        display: none;
    }
    .mat-form-field-infix {
        border: 0;
        width: 83px;
    }
    &.mat-form-field-appearance-fill {
        .mat-select-arrow-wrapper {
            transform: translateY(-10%);
        }
        .mat-form-field-flex {
            padding: 1em 1em 0 1em;
            background-color: $white;
        }
        .mat-form-field-infix {
            padding: .25em 0 1em 0;
        }
    }
    .mat-select-arrow,
    .mat-select-value {
        color: $primary;
    }
    &.style-1 {
        font-size: 16px;
        &.mat-form-field-appearance-fill .mat-form-field-infix {
            padding: 6px 0 20px 0;
        }
        &.mat-form-field-appearance-fill .mat-form-field-flex {
            border-radius: $radius;
            background-color: $white;
            padding: 12px 20px 0;
        }
        @include respond ('laptop') {
            font-size: 0.813rem;
            &.mat-form-field-appearance-fill .mat-form-field-infix {
                padding: 6px 0 12px 0;
            }
            &.mat-form-field-appearance-fill .mat-form-field-flex {
                padding: 8px 15px 0;
            }
        }
    }
}

.compose-content .dropzone {
    display: flex;
    align-items: center;
    justify-content: center;
}

.latest-salebx {
    height: 630px;
    @include respond ('tab-land') {
        height: 350px;
    }
}

//default-select
.post-input .btn-social {
    margin-right: 4px;
}

.rating-widget {
    .ng-star-inserted {
        color: $primary;
        font-size: 30px;
    }
}

//accordion
.default-accordion {
    box-shadow: none;
    margin-bottom: 1.25rem;
    height: unset;
    border-radius: 5px;
    .card-header {
        padding: 0;
        border: 0;
        button {
            padding-right: 40px;
            border-radius: 5px;
            background: $primary;
            color: $white;
            display: block;
            border: 0;
            width: 100%;
            text-align: left;
            &.collapsed {
                background: rgba($primary, 0.15);
                color: $black;
                &:after {
                    content: "\e61a";
                }
            }
            &:focus {
                box-shadow: none;
            }
            &:after {
                position: absolute;
                font-family: 'themify';
                top: 50%;
                content: "\e622";
                transform: translateY(-50%);
                right: 20px;
            }
        }
    }
}

.custom-header-accordion {
    border: 1px solid $border-color;
    box-shadow: none;
    margin-bottom: 0;
    height: unset;
    .card-header {
        button {
            &:focus {
                box-shadow: none;
            }
        }
    }
}

.prevent-accordion {
    box-shadow: none;
    margin-bottom: 1.25rem;
    height: unset;
    border-radius: 5px;
    .card-header {
        padding: 0;
        border: 0;
        button {
            background: $primary;
            border-radius: 5px;
            color: $white;
            display: block;
            padding-right: 40px;
            border: 0;
            width: 100%;
            text-align: left;
            &.collapsed {
                background: rgba($primary, 0.15);
                color: $black;
                &:after {
                    content: "\e61a";
                }
            }
            &:focus {
                box-shadow: none;
            }
            &:after {
                position: absolute;
                font-family: 'themify';
                top: 50%;
                content: "\e622";
                transform: translateY(-50%);
                right: 20px;
            }
        }
    }
}

//Nestable
.default-nestable {
    .dd-dragel .ngx-nestable-hidden,
    .dd-dragel button {
        visibility: hidden;
    }
    .dd-list {
        .dd-item {
            .nestable-item-container {
                background: $primary-light;
                color: $black;
                position: relative;
                display: flex;
                flex-direction: row;
                align-items: center;
                min-height: 32px;
                font-size: 16px;
                font-family: Roboto, sans-serif;
                cursor: pointer;
                outline: 0;
                border-radius: 5px;
                margin-bottom: 3px;
                padding: 10px 10px;
                @include transitionMedium;
                &:hover {
                    background: $primary;
                    color: $white;
                    button {
                        color: $white;
                    }
                }
                button {
                    font-family: 'Material Icons';
                    font-weight: normal;
                    font-style: normal;
                    outline: 0;
                    border: 0;
                    font-size: 24px;
                    line-height: 1;
                    letter-spacing: normal;
                    text-transform: none;
                    margin-right: 10px;
                    background: transparent;
                    color: $black;
                    display: inline-block;
                    white-space: nowrap;
                    word-wrap: normal;
                    direction: ltr;
                    -webkit-font-feature-settings: 'liga';
                    -webkit-font-smoothing: antialiased;
                }
            }
        }
    }
    /* .nestable-row{
		display:flex;
	}
	.ngx-nestable{
		width:50%;
	}*/
    .result-bx {
        textarea {
            height: 100%;
            width: 100%;
        }
    }
    @include respond ('tab-port') {
        .result-bx {
            margin-top: 30px;
            textarea {
                height: 600px;
                width: 100%;
            }
        }
    }
}

//alert
.alert-dismissible .close {
    height: 100%;
    font-size: 30px;
    span {
        font-weight: 400;
    }
}

//datePicker
.calendar-responsive {
    overflow: auto;
    display: block;
}

.default-calendar {
    ngb-datepicker {
        border: 0;
        background: lighten($primary, 33%);
    }
    .form-group {
        .input-group {
            .form-control {
                color: $black;
            }
            .input-group-append button {
                background-image: none!important;
                padding: 0px;
                border-color: $primary;
                background: $primary;
                width: 45px;
                text-align: center;
                &:after {
                    color: $white;
                    content: "\f133";
                    font-family: 'Line Awesome Free';
                    padding: 0;
                    font-size: 26px;
                }
                &:hover {
                    background: $primary;
                    &:after {
                        color: $white;
                    }
                }
                &:focus {
                    box-shadow: none;
                }
            }
        }
    }
    .weekend {
        background-color: $primary;
    }
    .ngb-dp-header {
        background: $primary;
        padding: 8px 5px;
        .ngb-dp-navigation-chevron {
            border-color: $white;
        }
        .custom-select {
            color: $white;
            background: $primary;
            border-radius: 5px;
            margin: 0 5px;
            padding: 0 20px;
        }
    }
    .ngb-dp-weekdays {
        border: 1px solid $primary;
        background: $white;
        .ngb-dp-weekday {
            color: $primary;
            font-weight: 600;
            font-size: 15px;
            font-style: unset;
        }
    }
    .ngb-dp-day,
    .ngb-dp-week-number,
    .ngb-dp-weekday {
        max-height: 40px;
        max-width: 40px;
        height: 40px;
        width: 35px;
        line-height: 40px;
    }
    [ngbDatepickerDayView] {
        max-height: 40px;
        max-width: 40px;
        width: 35px;
        height: 40px;
        line-height: 40px;
        &:active {
            background-color: $primary!important;
            color: white!important;
        }
    }
    .ngb-dp-navigation-select {
        flex: unset;
    }
    .custom-day {
        height: 40px!important;
        line-height: 40px;
        padding: 0px!important;
        width: 100%!important;
    }
    .custom-day.range,
    .custom-day:hover {
        height: 40px;
        background-color: $primary!important;
    }
    .ngb-dp-month-name {
        padding: 8px 0px;
        height: auto;
    }
}

//calendar
.dashboard-full-calendar {
    &.fc .fc-button-primary {
        background-color: $primary;
        color: $white;
        border: 0;
        &:hover,
        &.fc-button-active,
        &:active {
            background-color: darken($primary, 20%)!important;
        }
        &:focus {
            box-shadow: none!important;
        }
    }
    &.fc-theme-standard td,
    &.fc-theme-standard th,
    &.fc-theme-standard .fc-scrollgrid,
    &.fc-theme-standard .fc-list {
        border-color: #ECF0F3;
    }
    .fc-daygrid-day.fc-day-today {
        background-color: $primary-light!important;
    }
    .fc-daygrid-dot-event {
        background: $white;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    }
    &.fc-theme-standard th {
        padding: 0.75rem 0.5rem;
        font-size: 1rem;
        font-weight: 500;
        a {
            color: #B5B5C3;
        }
    }
    .fc-h-event,
    .fc-v-event {
        background-color: $primary;
        border-color: $primary;
    }
    .fc-daygrid-event-dot,
    .fc-list-event-dot {
        border-color: $primary!important;
    }
    @include respond ('phone-land') {
        &.fc .fc-toolbar {
            display: block;
            text-align: center;
            .fc-toolbar-title {
                margin: 10px;
            }
        }
    }
}

.calendar-list {
    margin-bottom: 10px;
    .list-group-item {
        background: #f4f6fa;
        color: $primary;
        border: 0;
        margin-bottom: 5px;
        border-radius: 6px;
        position: relative;
        border-left: 4px solid $primary;
    }
}

.calendar-checkbox {
    border-radius: 6px;
    background: $primary-light;
    padding: 10px 15px;
    margin-bottom: 5px;
    color: $black;
    label {
        margin-bottom: 0;
    }
    input[type=checkbox],
    input[type=radio] {
        margin-right: 6px;
    }
}

.calendar-description {
    h2 {
        margin-top: 30px;
        font-size: 25px;
        font-weight: 600;
        color: $black;
    }
    ul {
        li {
            font-size: 15px;
            color: $black;
        }
    }
}

//carousel
.default-carousel {
    .carousel-caption {
        background: rgba($black, 0.5);
        bottom: 45px;
        h3 {
            color: $white;
        }
        @include respond ('phone') {
            width: 90%;
            left: 50%;
            transform: translatex(-50%);
        }
    }
    .carousel-indicators {
        li {
            @include transitionMedium;
            height: 13px;
            width: 13px;
            border: 0;
            border-radius: 13px;
            background: $primary;
            &.active {
                width: 30px;
            }
        }
    }
}

//rating
.default-rating {
    span {
        font-size: 35px;
    }
}

//pagination
.pagination-responsive {
    overflow: auto;
}

.pagination .page-item .page-link span {
    color: #B1B1B1;
}

.pagination .page-item .page-link:hover span {
    color: $white;
}

//progressbar
.progress {
    height: 16px;
}

.form-select {
    &.mat-form-field {
        display: block;
    }
    .mat-form-field-infix {
        border-top: 0;
    }
    .mat-form-field-underline {
        display: none;
    }
    &.mat-form-field-appearance-fill {
        .mat-form-field-flex {
            border-radius: $radius;
            padding: 15px 15px 8px;
            background: transparent;
            border: 1px solid $border-color;
        }
        .mat-select-arrow-wrapper {
            transform: translateY(0%);
        }
    }
}

.btn-view {
    color: black;
    background-color: white;
    padding: .2rem;
    border-radius: 5px;
    margin-right: 0 !important;
    border-color: transparent;
}


/** Material Scsss  start**/


/* auto compelete */

.default-autocomplete {
    .mat-form-field {
        width: 100%;
    }
    /* .mat-autocomplete-trigger{
		margin: 1px;
		border:1px solid $border-color;
		border-radius:5px;
		width:100%;
		padding: 15px 20px;
	} */
    /* .mat-form-field-appearance-legacy .mat-form-field-infix{
		padding:0;
		.mat-input-element{
			margin: 1px;
			border:1px solid $border-color;
			border-radius:5px;
			padding: 15px 20px;
		}
	} */
    /* .mat-form-field-appearance-legacy .mat-form-field-label{
		color:rgba($black,0.5);
		left: 15px;
		top: 30px;
	} */
    .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill+.mat-form-field-label-wrapper .mat-form-field-label,
    .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
    .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label {
        /* transform: translateY(-2rem) scale(0.85) perspective(100px) translateZ(0.001px);
		-ms-transform: translateY(-2rem) scale(0.85); */
        color: $primary;
    }
    .mat-form-field.mat-focused .mat-form-field-ripple {
        background-color: $primary;
    }
}


/* badge  */

.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary,
.mat-badge-content {
    background-color: $primary;
}

.mat-badge-accent .mat-badge-content {
    background: $secondary;
    color: $white;
}


/* Bottom Sheet  */

.mat-bottom-sheet-container {
    max-height: 80vh;
    overflow: auto;
    ul li a {
        padding: 15px 15px;
        display: block;
        &:hover,
        &:focus {
            background: rgba(0, 0, 0, .04);
        }
        span {
            color: $black;
            display: block;
            font-size: 16px;
            &:last-child {
                font-size: 14px;
            }
        }
    }
}


/* button  */

.default-mt-btn {
    .mat-button.mat-primary,
    .mat-icon-button.mat-primary,
    .mat-stroked-button.mat-primary {
        color: $primary;
    }
    .example-label {
        font-size: 1rem!important;
        color: $black;
    }
    .example-button-row {
        width: auto!important;
        .mat-button-base {
            margin: 15px 15px 15px 0!important;
        }
    }
    .mat-flat-button.mat-accent,
    .mat-raised-button.mat-accent,
    .mat-fab.mat-accent,
    .mat-mini-fab.mat-accent {
        background-color: $secondary;
    }
    .mat-flat-button.mat-accent,
    .mat-raised-button.mat-accent,
    .mat-fab.mat-accent,
    .mat-mini-fab.mat-accent {
        color: $white;
    }
    .mat-button.mat-accent,
    .mat-icon-button.mat-accent,
    .mat-stroked-button.mat-accent {
        color: $secondary;
    }
    .mat-button.mat-accent .mat-button-focus-overlay,
    .mat-icon-button.mat-accent .mat-button-focus-overlay,
    .mat-stroked-button.mat-accent .mat-button-focus-overlay {
        background-color: $secondary;
    }
    .mat-button,
    .mat-raised-button,
    .mat-stroked-button,
    .mat-flat-button {
        padding: 0 25px;
        line-height: 55px;
        border-radius: $radius;
        font-size: 1rem;
    }
    @include respond ('laptop') {
        .example-button-row {
            width: auto!important;
            .mat-button-base {
                margin: 10px 10px 10px 0!important;
            }
        }
        .mat-button,
        .mat-raised-button,
        .mat-stroked-button,
        .mat-flat-button {
            padding: 0 15px;
            line-height: 40px;
            font-size: 14px;
        }
    }
    @include respond ('phone') {
        .btn-list-group {
            display: block!important;
            padding: 10px 0px;
            .example-label {
                display: block!important;
            }
        }
        .example-button-row {
            width: auto!important;
            .mat-button-base {
                margin: 5px 10px 5px 0!important;
            }
        }
        .example-button-container {
            width: 80px!important;
        }
    }
}


/* card  */

.default-mt-card {
    .mat-card {
        border-radius: $radius;
    }
}


/* checkbox  */

.default-mt-checkbox {
    .example-section {
        margin: 0!important;
    }
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
    background: $primary;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background,
.mat-checkbox-checked.mat-primary .mat-checkbox-background {
    background-color: $primary;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
    background: $primary;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: $primary;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: $secondary;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
    background: $secondary;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: $secondary;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color: $secondary;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
    background: $secondary;
}


/* chips  */

.default-mt-chips {
    .mat-input-element {
        color: rgba($black, 0.6);
    }
    .mat-chip.mat-standard-chip,
    .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
        background-color: $primary;
        color: $white;
    }
    .mat-form-field-appearance-legacy .mat-form-field-label,
    .mat-form-field-appearance-legacy .mat-hint,
    input.mat-chip-input::placeholder {
        color: rgba(0, 0, 0, .54);
    }
    .mat-form-field.mat-focused .mat-form-field-label {
        color: $primary;
    }
    .mat-form-field.mat-focused .mat-form-field-ripple {
        background-color: $primary;
    }
    .mat-chip.mat-standard-chip .mat-chip-remove {
        opacity: 1;
        color: rgba($white, 0.8);
    }
    .mat-form-field-appearance-legacy .mat-form-field-underline {
        background-color: rgba(0, 0, 0, .42);
    }
    .mat-form-field-infix {
        width: 100%;
        color: $white;
    }
    .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
        background-color: $secondary;
        color: $white;
    }
}


/* datepicker  */

.default-mt-datepicker {
    .mat-form-field-appearance-fill .mat-form-field-flex {
        background-color: rgba($primary, 0.1);
    }
    .mat-form-field-label {
        color: rgba($black, 0.6);
    }
    .mat-date-range-input-inner,
    .mat-calendar-body-cell-content,
    .mat-date-range-input-separator {
        color: $black;
    }
    .mat-icon-button svg path {
        fill: rgba($black, 0.7);
    }
    .mat-form-field.mat-focused .mat-form-field-ripple {
        background: $primary;
    }
    .mat-form-field.mat-focused .mat-form-field-label {
        color: $primary;
    }
    .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
        color: rgba($black, 0.5);
    }
    .mat-form-field-appearance-fill .mat-form-field-underline:before {
        background-color: rgba(0, 0, 0, .42);
    }
    .mat-input-element:disabled,
    .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
        color: $black;
    }
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
    background: rgba($secondary, 0.2);
}

.mat-calendar-body-selected {
    background-color: $primary;
}

.mat-calendar-body-in-range::before {
    background: rgba($primary, 0.1);
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-keyboard-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba($primary, 0.3);
}

.mat-datepicker-content-container .example-header {
    button {
        height: 35px;
        width: 35px;
        outline: none;
        border: 0;
        border-radius: 35px;
        font-size: 18px;
        margin: 0 2px;
    }
}


/* dialog  */


/* divider  */


/* expansion  */

.default-mt-expansion {
    .mat-expansion-panel-header-description {
        justify-content: space-between;
        align-items: center;
    }
    .mat-accordion .mat-expansion-panel:first-of-type {
        border-top-right-radius: $radius;
        border-top-left-radius: $radius;
    }
    .mat-accordion .mat-expansion-panel:last-of-type {
        border-bottom-right-radius: $radius;
        border-bottom-left-radius: $radius;
    }
    .mat-expansion-panel-header {
        height: auto;
        padding: 15px 20px!important;
    }
}


/* form-field  */

.default-mt-formfield {
    .mat-form-field {
        width: 100%;
    }
    .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
        color: $primary;
    }
    .example-container .mat-form-field+.mat-form-field {
        margin-left: 0!important;
    }
}


/* input  */

.default-mt-input {
    .example-form {
        width: 100%!important;
        min-width: 150px;
        max-width: 100%!important;
    }
}


/* progress  */

.default-mt-progress {
    .mat-progress-bar-buffer {
        background-color: $primary-light;
    }
    .mat-progress-bar-fill::after {
        background-color: $primary;
    }
    .mat-progress-bar {
        height: 8px;
        border-radius: 8px;
        pattern circle {
            fill: $primary-light;
        }
    }
    .example-section {
        height: auto!important;
    }
    .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
        background-color: $secondary;
    }
    .mat-progress-bar.mat-accent .mat-progress-bar-background {
        fill: $secondary;
    }
    .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
        background-color: $secondary;
    }
}

.mat-accent .mat-slider-track-fill,
.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label {
    background-color: $secondary;
}


/* spinner  */

.mat-progress-spinner.mat-accent circle,
.mat-spinner.mat-accent circle {
    stroke: $secondary;
}

.mat-progress-spinner circle,
.mat-spinner circle {
    stroke: $primary;
}

.default-mt-spinner {
    .example-margin {
        margin: 0 10px;
    }
}


/* select  */

.default-mt-select {
    .mat-form-field.mat-focused .mat-form-field-label {
        color: $primary;
    }
}


/* sidenav */

.default-mt-sidenav {
    .example-container {
        margin: 0!important;
        width: 100%!important;
        height: 350px!important;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
        border: 0!important;
    }
    .mat-drawer-content {
        padding: 25px!important;
    }
}


/* slidetoggle */

.default-mt-slidetoggle {
    .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
        background-color: $primary-light;
    }
    .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
        background-color: $primary;
    }
    .example-section {
        height: auto!important;
    }
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: rgba($secondary, 0.5);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: $secondary;
}

.mat-slide-toggle.mat-checked .mat-ripple-element {
    background-color: $secondary;
}


/* slider */

.default-mt-slider {
    .example-section {
        display: block!important;
        height: auto!important;
    }
}


/* sort */

.default-mt-sort {}


/* stepper */

.default-mt-stepper {
    .mat-step-header .mat-step-icon-selected,
    .mat-step-header .mat-step-icon-state-done,
    .mat-step-header .mat-step-icon-state-edit {
        background-color: $primary;
    }
    @include respond ('phone') {
        .mat-horizontal-stepper-header-container {
            display: block;
            .mat-horizontal-stepper-header {
                padding: 0;
                height: 60px;
                margin-bottom: 10px;
                margin-top: 10px;
            }
        }
        .mat-horizontal-content-container {
            padding: 0;
        }
        .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::before,
        [dir=rtl] .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::before,
        .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::after,
        [dir=rtl] .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::after {
            content: none;
        }
        .mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
            top: 0;
        }
    }
}


/* table */

.default-mt-table {
    .mat-elevation-z8 {
        box-shadow: none;
    }
    .table-responsive {
        border-radius: $radius;
        position: relative;
        z-index: 1;
    }
    .mat-header-cell {
        color: $black;
        padding: 0 10px;
        font-size: 16px;
        font-weight: 500;
    }
    .mat-footer-cell {
        padding: 0 10px;
    }
    .mat-cell {
        padding: 0 10px;
    }
    .example-element-diagram {
        min-width: 100px!important;
        font-weight: 400!important;
        height: 130px!important;
    }
    mat-row,
    mat-header-row,
    mat-footer-row,
    th.mat-header-cell,
    td.mat-cell,
    td.mat-footer-cell {
        border-color: $border-color;
    }
    table.mat-table {
        //	width:100%;
    }
    .stickyColumns-table {
        .mat-table-sticky {
            background: $primary!important;
            .mat-header-cell {
                color: $white;
            }
            .mat-cell {
                color: $white;
            }
            .mat-footer-cell {
                color: $white;
            }
        }
        .mat-table-sticky-border-elem-left,
        .mat-table-sticky-border-elem-right,
        .mat-table-sticky-border-elem-bottom,
        .mat-table-sticky-border-elem-top {
            border-color: $white;
            color: $white;
        }
        .mat-column-filler {
            font-size: 12px;
        }
        mat-cell:first-of-type,
        mat-header-cell:first-of-type,
        mat-footer-cell:first-of-type {
            padding-right: 10px;
        }
        mat-cell:last-of-type,
        mat-header-cell:last-of-type,
        mat-footer-cell:last-of-type {
            padding-left: 10px;
        }
        .mat-header-cell,
        .mat-footer-cell,
        .mat-cell {
            min-width: 100px!important;
        }
        .mat-header-row,
        .mat-footer-row,
        .mat-row {
            min-width: 2300px!important;
        }
    }
}


/* tabs */

.default-mt-tabs {
    .mat-tab-group.mat-primary .mat-ink-bar,
    .mat-tab-nav-bar.mat-primary .mat-ink-bar {
        background-color: $primary;
    }
    .mat-tab-body-content {
        padding: 10px 15px;
    }
    .mat-tab-group.mat-background-primary>.mat-tab-header,
    .mat-tab-group.mat-background-primary>.mat-tab-link-container,
    .mat-tab-group.mat-background-primary>.mat-tab-header-pagination,
    .mat-tab-nav-bar.mat-background-primary>.mat-tab-header,
    .mat-tab-nav-bar.mat-background-primary>.mat-tab-link-container,
    .mat-tab-nav-bar.mat-background-primary>.mat-tab-header-pagination {
        background-color: $primary;
    }
    .mat-tab-group.mat-background-accent>.mat-tab-header,
    .mat-tab-group.mat-background-accent>.mat-tab-link-container,
    .mat-tab-group.mat-background-accent>.mat-tab-header-pagination,
    .mat-tab-nav-bar.mat-background-accent>.mat-tab-header,
    .mat-tab-nav-bar.mat-background-accent>.mat-tab-link-container,
    .mat-tab-nav-bar.mat-background-accent>.mat-tab-header-pagination {
        background-color: $secondary;
    }
    .mat-tab-group.mat-accent.mat-background-accent>.mat-tab-header .mat-ink-bar,
    .mat-tab-group.mat-accent.mat-background-accent>.mat-tab-link-container .mat-ink-bar,
    .mat-tab-nav-bar.mat-accent.mat-background-accent>.mat-tab-header .mat-ink-bar,
    .mat-tab-nav-bar.mat-accent.mat-background-accent>.mat-tab-link-container .mat-ink-bar {
        background-color: $white;
    }
    .mat-tab-group.mat-background-accent>.mat-tab-header .mat-tab-label,
    .mat-tab-group.mat-background-accent>.mat-tab-link-container .mat-tab-link,
    .mat-tab-nav-bar.mat-background-accent>.mat-tab-header .mat-tab-label,
    .mat-tab-nav-bar.mat-background-accent>.mat-tab-link-container .mat-tab-link {
        color: rgba($white, 0.8);
    }
}


/* tree */

.default-mt-tree {
    .mat-checkbox-layout {
        margin: 0;
    }
    .mat-tree {
        background: rgba($black, 0.05);
        overflow: auto;
    }
}


/* toolbar */

.default-mt-toolbar {
    .mat-toolbar.mat-primary {
        background: $primary;
    }
}
// @import url('../abstracts/_abstracts.scss');textAccOrdersIt
$dashboard-color-primary: #EF4A49;
$dashboard-color-primaryAlternative: #F26E6D;
$dashboard-color-primaryTransparent: rgba(239, 74, 73, 0.8);
$dashboard-color-secondary: #3E174C;
$dashboard-color-secondaryTransparent: rgba(62, 23, 76, 0.71);
$dashboard-color-terciary: #F1ECDE;
$dashboard-color-terciaryTransparent: rgba(241, 236, 222, 0.9);
$dashboard-color-backgroundW: white;
$dashboard-color-black: #222222;
$dashboard-color-white: white;
$dashboard-color-textPrimary: #414141;
$dashboard-color-textSecondary: #808080;
$dashboard-color-approvalPrimary: #09B397;
$dashboard-color-approvalSecondary: #09967f;
$dashboard-color-disapprovalPrimary: #EF4A49;
$dashboard-color-disapprovalSecondary: #F26E6D;
$dashboard-color-pendingPrimary: #FFC978;
$dashboard-color-pendingSecondary: #ffe0b3;
$dashboard-color-itemsIn: #C8C8C8;
$dashboard-color-itemsOut: #E6E6E6;
$dashboard-color-terciaryAlternative: #E2DACC;
$dashboard-color-terciaryAlternativeStrong: #d8d1c4;
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700");
// @use '_customer'; //esto para traer las var definidas en _customer.scss y para usarlas customer.$color-primary

body {
  font-family: 'Montserrat', sans-serif;
}


.container-fluid {
  max-width: 1440px !important;
}


*::-webkit-scrollbar {
  width: 0;
  /* width of the entire scrollbar */
}

*::-webkit-scrollbar-track {
  background: transparent
    /* color of the tracking area */
}

*::-webkit-scrollbar-thumb {
  background: transparent;
  /* color of the scroll thumb */
  border-radius: 0px;
  /* roundness of the scroll thumb */
  background: transparent;
  /* creates padding around scroll thumb */
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


/*To avoid color :auto-fill pseudo-class in inputs when "user agent style" is activated */
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s !important;
}

input[type=checkbox]:after {
  display: none !important;
}

//---------------------------- Mat Steeper from Material ------------------------------------------------//


.mat-step-header .mat-step-icon-selected {
  background-color: #EF4A49 !important;
}

.mat-step-text-label {
  font-family: 'Montserrat';
  line-height: 1rem;
}

.mat-step-label-active {
  font-weight: 700;
  color: #EF4A49 !important;
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #EF4A49 !important;
  color: #fff;
}

.mat-button.mat-primary {
  color: white !important;
}


//----------------------------- Tool Tips ---------------------------------------------------------//

.mat-tooltip {
  color: white !important;
  font-family: "Montserrat";
  background-color: #7f7f7f !important;
  letter-spacing: 0.05rem;
}

//---------------------------- Inputs from Material ------------------------------------------------//


.mat-form-field,
.mat-select,
.mat-input-element {
  font-family: 'Montserrat' !important;
}

// To remove the space when remove the float label, usign "outline"

.mat-form-field-infix {
  border-top-width: 5px;
}


// Change the color of hover by this. Here you can give any color you want. -------------------------

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  // color: #86b7fe !important;
  color: #cccccc !important;

}

// Change the color of mat-input error outline color by this -------------------------

.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #EF4B48 !important;
  opacity: 0.8 !important;
}

// Controling text error size and input separation

.mat-form-field-subscript-wrapper {
  font-size: 0.6rem;
  font-family: 'Montserrat';
  font-weight: 500;
}


.mat-form-field-wrapper {
  padding-bottom: 1rem;
}

//  Change the color of text in select element and text cursor by this. -------------------------
.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  // color: #64a1fc !important;
  color: #7e7e7e !important;
  font-weight: 600;
}

.mat-input-element {
  // caret-color: #86b7fe;
  caret-color: #aaaaaa;
}

.mat-select-arrow {
  color: #aaaaaa;

  &:hover {
    color: #aaaaaa !important;

  }
}

// To control de underline above the error message
.mat-form-field-underline {
  display: none !important;
}

// Change the color of check-box element  by this. -------------------------

.form-check-input:checked {
  background-color: #EF4A49 !important;
  border-color: #EF4A49 !important;

  &:hover,
  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 58, 81, 0.25);

  }
}

// Esto se debe a cdk-global-scrollblockque se inyecta en el HTML body, lo que afectará a sus componentes que tienen una posición absoluta.
// https://stackoverflow.com/questions/47782890/angular-material-dialog-component-hides-all-my-website-components

.cdk-global-scrollblock {
  position: static;
  overflow: hidden !important;
}


//-----------------------------Alerts  Swal ---------------------------------------------//
.swal2-title,
.textAlert {
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.5rem;
  color: $dashboard-color-textPrimary;
}

.swal2-html-container {
  font-size: .8rem !important;
}

.swal2-styled.swal2-confirm.swalBtnColor {
  color: $dashboard-color-white;
  background-color: $dashboard-color-disapprovalSecondary;

  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(0, 202, 91, 0.25);
    border: none;
  }
}

//----------------------------------Login ---------------------------------------------//

.logo-login {
  width: 200px;
}

//----------------------------------Login end -------------------------------------------//


//--------------------------------- Dashboard Nav-Header|Navigation|Header init---------------------//

//Navigation
.nav-text-vlc {
  padding-left: 5px;
  font-size: 14px;
}


// Btn switch Open - Close 2
.openClose {
  display: flex;
  align-items: center;
}

// input[type=checkbox]::after {
//   display: none !important;

// }

.btn-switchNav {
  display: flex;
  flex-direction: column;
  align-items: center !important;
  padding-left: 0px;
  background-color: $dashboard-color-terciary;
  border-radius: 0.2rem;
  width: max-content;
  height: 40px;
  text-align: center;
  margin-right: 1rem;

}

.formSwitch {
  display: flex;
  justify-content: center;
  padding-left: 0rem !important;
  margin: 0rem 0rem 0rem 0rem;
}

.switchNavText {
  text-align: center;
  font-size: 10px;
  font-weight: bold;
  color: $dashboard-color-textPrimary;
  padding: 1.5rem 0.2rem 0.3rem 0.2rem;
  margin: 0;
  min-height: 1.1rem !important;
  // padding: 1.5rem 0.2rem 0.3rem 0.2rem;
}

.formCheckInput:checked {
  margin: 0.5rem !important;
  background-color: $dashboard-color-approvalPrimary !important;
  border-color: $dashboard-color-approvalPrimary !important;

  &:hover,
  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(0, 202, 91, 0.25);
    border: none;
  }
}

.formCheckInput {
  margin: 0.5rem !important;
  background-color: $dashboard-color-primary;
  border-color: $dashboard-color-primary;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e") !important;

  &:hover,
  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(197, 0, 10, 0.25);
    outline: 0;
  }
}

//--------------------------------- Dashboard Nav-Header|Navigation|Header end---------------------//


//--------------------------------- Dashboard Orders pending init ----------------------------------------//


//Items int card------------------------------
.icon-text {
  color: $dashboard-color-textPrimary;
  font-size: 0.9rem;
  margin-bottom: 0px;
}

//btn------------------------
// Orders action buttons------------------------
.containerBtnAccOrders {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 2rem 1rem;
}

.total {
  display: flex;
  justify-content: center;
  color: $dashboard-color-textPrimary ;
}

.textAccTotalMesa {
  margin-bottom: 0rem;
  color: $dashboard-color-textPrimary;
}

.containerbtnAccMesa {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}

// Order btn------------------------------

.btnConfirmOrder {
  gap: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 8px;
  color: $dashboard-color-white ;
  background-color: $dashboard-color-approvalPrimary;
  height: 2rem;

  &:hover {
    color: $dashboard-color-white ;
    background-color: $dashboard-color-approvalSecondary;
  }

  &:focus {
    outline: 0;

    &:focus {
      box-shadow: 0 0 0 0.20rem rgba(0, 196, 104, 0.3) !important;
    }
  }
}

.btnCancelOrder {
  width: 100%;
  border-radius: 8px;
  height: 2rem;
  color: $dashboard-color-white;
  background-color: $dashboard-color-primary;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    color: $dashboard-color-white;
    background-color: #db3232;
  }

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(255, 38, 38, 0.25);
  }
}

.mat-icon {
  height: 17px !important;
  font-size: 17px !important;
  font-weight: 500;
  vertical-align: middle;
}



// Table btn------------------------------------------


.btnAccordConfirmMesa {
  width: 100%;
  color: $dashboard-color-primary;
  background-color: #ffd4d4;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    color: $dashboard-color-primary ;
    background-color: #fcb1b1;
  }

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(255, 38, 38, 0.25);
  }
}


//// Accordion winery--------------------------


// Toast -------------------------------

.textToastColor {
  color: $dashboard-color-textPrimary;
}

.text-color-toastNewOrder {
  color: #FFB13D;
}

.text-color-toastOrderSend {
  color: $dashboard-color-approvalSecondary;
}

.text-color-toastOrderCancel {
  color: $dashboard-color-primary;
}

//--------------------------------- Dashboard Orders end ----------------------------------------//


//---------------------------------Nav and Dashboard Order history init --------------------------------//
//Nav Order history init ------------
.orderStatusTitle {
  display: flex;
  flex-direction: column;
  // align-items: center;
  // justify-content: space-between ;
  width: 100%;
  gap: 2rem;
}

//Order closed component
.tableOrderClose {
  width: 100%;
  overflow: scroll;
}

.paginationOrders {
  display: flex;
  justify-content: center;
  // padding-top: 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

//Nav Order history end ------------
//Dashboard Order history init ---------


.nav-tabsOrderList {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: nowrap;
  overflow: auto;
  overscroll-behavior-inline: contain;
  scrollbar-width: none;
  gap: 1rem;
}



.badgeorderlistin {
  border-radius: 30px;
  color: $dashboard-color-textPrimary ;
  background-color: $dashboard-color-itemsIn ;
  font-size: 12px;
  margin-top: 0px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.badgeorderlistout {
  border-radius: 30px;
  color: $dashboard-color-textPrimary ;
  background-color: $dashboard-color-itemsOut ;
  font-size: 12px;
  margin-top: 0px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.badge-in-vlc {
  background-color: $dashboard-color-white ;
  color: $dashboard-color-textPrimary ;
  font-size: 12px;
}

//modal

.modalContentOrderClosed {
  border-radius: 8px;
  border: 0px;
}

.modalHeaderOrderClosed {
  background-color: $dashboard-color-primary;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border: solid 2px $dashboard-color-primary;
}

.modalBodyOrder {
  padding-bottom: 0px !important;
}

.btnCloseModal {
  color: $dashboard-color-white ;
  background-color: $dashboard-color-primary ;
  border-radius: 10px;
  border-color: $dashboard-color-primary ;

  &:hover,
  &:focus {
    color: $dashboard-color-white ;
    font-weight: 700;
    background-color: $dashboard-color-primary ;
    border-color: $dashboard-color-primary ;
  }
}

//Dashboard Order history end ---------

//--------------------------------- Nav and ashboard Order history end ----------------------------------//


//-------------------------------- Order, order history, liquidation dual header init ------------------------------------------//

.btnRestoProv {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
  margin-top: 1rem;
}

.textMsgorderHistDual {
  max-width: 336px;
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 1rem;
  margin-bottom: 0px;
}

.btnRestoProvOrders {
  @extend btnRestoProv !optional;
  flex-direction: column;
  width: 100%;
}

.containerRestoProvOrders {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.3rem;
  margin-bottom: 2rem;
  margin-top: 1rem;
}

.activeVlc.active {
  background: #F1ECDE !important;
  color: #414141 !important;
  font-weight: 650 !important;

  &:hover,
  &:focus {
    box-shadow: 0 0 0 0.20rem rgba(173, 169, 123, 0.3);
  }
}



//-------------------------------- Order  liquidation dual header end ----------------------------------------//

//--------------------------------- Update Item top panel init ------------------------------//

.itemTableFilterChipsAdm {
  width: 100%;
}

.wineFilterDivHidden {
  display: none !important;
  visibility: hidden;
}

.topPanelFiltersAdm {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  // margin-bottom: 0.8rem;
}

.topPanelFiltersAdmFoodFliter {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  // margin-bottom: 0.8rem;
}

.topPanelFiltersMat {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
}

.inputAndChipWineFilterAdm {
  display: flex;
  flex-direction: column;
  // gap: 0.5rem;
  width: 100%;
}

.inputMatHeight {
  height: 41px;
}

.topPanelContainer,
.topPanelContainerDAta,
.topPanelContainerItemTable {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
  gap: 1.5rem;
}

.itemTableBoxSearchLiquid {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.7rem;
}

.itemTableBoxSearch {
  @extend itemTableBoxSearchLiquid !optional;
  width: 100%;
}

.itemTableBoxSearchNoTitle {
  width: 100%;
  display: flex;
  // flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  gap: 0.7rem;
}

.topPanelFilters {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
}


.topPanelItemEan {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.topPanelNoCure {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.topPanelItemCellar {
  width: 100%;
  display: flex;
  gap: 1rem;
}

.topPanelItemSearchProduct {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $dashboard-color-black;
  gap: 0.5rem;

}


.topPanelItemSearchText {
  display: flex;
  justify-content: flex-start;
  font-size: 0.8rem;
  font-weight: 700;
  color: $dashboard-color-textPrimary;
  margin-bottom: 0rem;
}


.btnUpdateItemSearch {
  background-color: $dashboard-color-primary;
  border-radius: 5px;
  color: $dashboard-color-white;
  font-size: 14px;

  &:hover {
    background-color: #d81613 !important;
    color: $dashboard-color-terciary;
    font-weight: 500;
  }
}



.matIconAddItem {
  display: flex;
  justify-content: center;
}

.btnUpdateItemNewItemTable {
  @extend .btnUpdateItemSearch;
  background-color: $dashboard-color-primary !important;
  color: $dashboard-color-white;
  height: 41px;

  &:hover {
    background-color: #d81613 !important;
    color: $dashboard-color-white;
    font-weight: 500;
  }
}

.containerUpdateItemInput {
  @extend .containerInputUpdateItem;

  &:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
  }
}

.containerUpdateItemSelect {
  @extend .containerInputUpdateItem;
  padding: 5rem;
  height: 56px;
  width: 200px;
}



// --------------------------------- Massive percentaje init --------------------------------------//


.massivePriceContainer {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.massivePriceDiv {
  display: flex;
  flex-direction: column;
  gap: 1rem;

}

.massiveSpan {
  font-weight: 400;
  font-size: 0.7rem;
  line-height: 0.7rem;
}


.green-card {
  background-color: #09B397 !important;
  color: white;
  box-shadow: none !important;

  &:hover {
    background-color: $dashboard-color-approvalSecondary !important;
  }

  &:focus {
    box-shadow: 0 0 0 0.20rem rgba(0, 196, 104, 0.3) !important;
  }
}

// --------------------------------- Massive percentaje end --------------------------------------//


.itemTablePercentaje {
  background-color: $dashboard-color-white;
  border-radius: 0.5rem;
  box-shadow: 0px 2px 5px 4px rgb(0 0 0 / 6%);
  margin-bottom: 2rem;
  padding: 3rem;
}

.itemTablePercentajeContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  width: 100%;
}

.itemTablePerBoxAndBtn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}

.itemTablePercentajeBox {
  display: flex;
  align-items: center;
  gap: 0.5rem;

}

.itemTableWarning {
  display: flex;
  gap: 0.5rem;

  p {
    display: flex;
    align-items: center;
    color: #555555 !important;
    margin-bottom: 0px;
    font-size: 0.7rem;
    font-weight: 500;
    line-height: 0.8rem;
  }

  span {
    font-size: 0.7rem;
    font-weight: 700;

    &:hover {
      color: #FFB13D !important;
      text-decoration: underline;
    }

    &:focus {
      color: #FFB13D !important;
      text-decoration: underline;
    }

  }
}

// --------------------------------- Massive percentaje end --------------------------------------//

// --------------------------------- Item-table (component) init --------------------------------------//

.containerInputItemTableFilter {
  width: 100%;
  border: 0.1rem solid #d4d4d4;
  border-radius: 10px;
  padding: .5rem;
  display: flex;
  justify-content: center;
  font-size: 0.75rem;
  color: #737373;
  letter-spacing: 0.01rem;
}

// --------------------------------- Item-table (component) end --------------------------------------//

//--------------------------------- Update Item init ----------------------------------------//

.theadUpdateTh {
  color: $dashboard-color-textPrimary !important;
  font-weight: 500;


}

.theadUpdateHeadershadow {
  box-shadow: 0px 2px 5px 4px rgb(0 0 0 / 6%);
  border-radius: 0.5rem;
  overflow-x: scroll;
  width: 100%;
  scroll-behavior: smooth;
}

.colorTheadUpdate {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #F2F3F5;
  padding: 1rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  font-weight: 700;
}

.colorTheadUpdate-text {
  color: $dashboard-color-textPrimary;

}

.nav-itemUpdateItem {
  margin: 0 !important;
}

.table-tabsUpdateItem {
  width: 100%;
}

.nav-tabsUpdateItem {
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: nowrap;
  overflow: auto;
  overscroll-behavior-inline: contain;
  scrollbar-width: none;
}


.btnHomePanel {
  display: flex;
  align-items: center;
  border-radius: 5px !important;
  white-space: nowrap;
  color: $dashboard-color-textPrimary;
  height: 27px;
  font-size: 0.8rem;
}


.btnCatUpdateItem {
  display: flex;
  align-items: center;
  justify-items: center;
  border-radius: 5px !important;
  white-space: nowrap;
  color: $dashboard-color-textPrimary;
  height: 41px;
}

.mediaUpdate {
  align-items: center;
  justify-content: center;

  .media-body {
    h5 {
      // color: #414141;
    }
  }
}

// ------ New buttons set in crud table init -------

.btnCrud {
  background-color: #f7f7f7;
  width: 1.3rem;
  height: 1.6rem;
  border-radius: 0.3rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 0.6rem;

  &:hover,
  &:focus {
    background-color: #e0e0e0;
    color: #414141;
    box-shadow: 0 0 0 0.12rem rgba(150, 150, 150, 0.3);
  }
}

// ------ New buttons set in crud table end -------

.btnAddUpdateItem {
  background-color: #E7F3FF;
  color: #1877F2;
  width: 5rem;
  height: 2rem;
  border-radius: 5px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 0.6rem;

  &:hover,
  &:focus {
    background-color: #c5e0fa;
    box-shadow: 0 0 0 0.20rem rgb(13 110 253 / 30%);
  }
}

.inputUpdateItem {
  max-width: 8rem;
  // max-width: 5rem;
  background: transparent;
  outline: none;
  border: none;

  &::placeholder {
    font-size: 1rem;
    color: rgb(36, 36, 36);
    font-weight: 400;
  }
}

.colorChange::placeholder {
  color: #FFAB2D;
}

.containerInputUpdateItem {
  width: auto;
  // max-width: 10rem;
  border: 0.1rem solid #d4d4d4;
  border-radius: 10px;
  padding: .5rem;
  display: flex;
  justify-content: center;
}

#qtyUpdateItem {
  width: auto;
  max-width: 2rem;
}

table {
  border: 0 none;
  border-collapse: collapse;
}

// .theadUpdate{
// background-color: rgba(239, 74, 73, 0.8);

// }

.rwd-table {
  background: transparent;
  color: #444;
  border-radius: 0.4em;
  overflow: hidden;
  height: 60%;

}

.rwd-table tr {
  border-color: #46637f;
  // border-top: 1px solid white ;
}

.rwd-table th,
.rwd-table td {
  margin: 0.5em 1em;
}

.rwd-table {
  /* margin: 1em 0; */
  min-width: 300px;
  width: 100%;
}

.rwd-table tr {
  // border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.rwd-table th {
  display: none;

}

.rwd-table td {
  display: block;
}

.rwd-table td:first-child {
  padding-top: 0.5em;
}

.rwd-table td:last-child {
  padding-bottom: 0.5em;
}

.rwd-table td:before {
  content: attr(data-th) ": ";
  font-weight: bold;
  width: 6.5em;
  display: inline-block;
}

.rwd-table th,
.rwd-table td {
  text-align: left;
}


.rwd-table th {
  color: #fff;

}

.rwd-table td:before {
  color: #444;
}

.theadUpdateMobile {
  display: block;
  // background-color: rgba(239, 74, 73, 0.8);
  color: white;
  font-size: 20px;
  text-align: center;
  border-radius: 10px 10px 0 0;
}


.tdFlexAction {
  justify-content: space-between;
}

.tdFlex {
  display: flex;
  align-items: center;
}

.img-itemUpdate {
  display: none;


}

.categoryUpdateItem {
  display: none;
}

.descriptionUpdateItem {
  margin-bottom: 0;
}




//--------------------------------- Update Item end ----------------------------------------//
//--------------------------------- QR ----------------------------------------//
.containerFormDataRowQr {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: self-end;
}

.qrGeneratorHeaderB {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 3rem;
}

//--------------------------------- QR ----------------------------------------//

//--------------------------------- BTN scanner EAN init ----------------------------------------//
.figureButtonCheck {
  margin: 0;
}


.btn-scanner {
  background-color: $dashboard-color-terciary;
  display: flex;
  gap: 0.1rem;
  justify-content: center;
  align-items: center;
  z-index: 50;
  border-radius: 5px;
  box-shadow: 10px 11px 15px -5px rgba(50, 50, 50, 0.10);
  width: 100%;
  height: 41px;

  p,
  figure svg {
    color: #414141;
    font-size: 0.8rem;
    font-weight: 600;
    padding: 0;
    margin: 0;
  }

}

.qr-scanner {
  // height: 10%;
  // width: 10%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  top: 25%;
  right: 10%;
  left: 10%;
  bottom: 25%;
  margin: auto;
  z-index: 400;
  // width: 100%;
  // height: 100%;
}

.qr-scanner .box {
  // width: 75vw;
  // height: 75vw;
  // max-height: 75vh;
  // max-width: 75vh;
  width: 20rem;
  height: 15rem;
  max-height: 15rem;
  max-width: 20rem;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  border: 0.1rem solid $dashboard-color-terciaryTransparent;
}

.qr-scanner .line {
  height: calc(100% - 2px);
  width: 100%;
  background: linear-gradient(180deg, rgba(0, 255, 51, 0) 43%, $dashboard-color-terciary 211%);
  border-bottom: 3px solid $dashboard-color-terciary;
  transform: translateY(-100%);
  animation: radar-beam 2s infinite;
  animation-timing-function: cubic-bezier(0.53, 0, 0.43, 0.99);
  animation-delay: 1.4s;
}

.qr-scanner .box:after,
.qr-scanner .box:before,
.qr-scanner .angle:after,
.qr-scanner .angle:before {
  content: '';
  display: block;
  position: absolute;
  width: 3vw;
  height: 3vw;

  border: 0.2rem solid transparent;
}

.qr-scanner .box:after,
.qr-scanner .box:before {
  top: 0;
  border-top-color: $dashboard-color-terciary;
}

.qr-scanner .angle:after,
.qr-scanner .angle:before {
  bottom: 0;
  border-bottom-color: $dashboard-color-terciary;
}

.qr-scanner .box:before,
.qr-scanner .angle:before {
  left: 0;
  border-left-color: $dashboard-color-terciary;
}

.qr-scanner .box:after,
.qr-scanner .angle:after {
  right: 0;
  border-right-color: $dashboard-color-terciary;
}

@keyframes radar-beam {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

//--------------------------------- BTN scanner EAN end----------------------------------------//


//-------------------------------- Pages Corkage - Menu filter - Products organize init ----------------//

.pagesPersonalizacion {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  // width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  // padding: 0rem 2rem;
}

.linkMenu {
  max-width: 470px;
}

//-------------------------------- Pages Corkage - Menu filter - Products end ----------------//
//--------------------------------- Corkage percentage init ----------------------------------------//

// .containerHoursSchedule {
//   display: flex;
//   flex-direction: column;
//   gap: 1rem;
//   width: 100%;
// }

 .title-schedule {
   text-align: center;
 }

.cardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  height: 100%;
  gap: 1rem;

  p {
    display: flex;
    align-items: center;
    max-width: 470px;
    font-weight: 500;
    font-size: 14px;
    color: $dashboard-color-textPrimary;
    margin-bottom: 0;
    line-height: 1rem;
  }

  a {
    max-width: 470px !important;

  }
}

.corkageText {
  display: flex;
  align-items: center;
  min-width: fit-content;
  font-weight: 500;
  font-size: 14px;
  color: $dashboard-color-textPrimary;
  margin-bottom: 0;
}

.cardBtnCancelAndSAve {
  display: flex;
  // flex-direction: column-reverse;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
}



.containerInputUpdateItemCork {
  @extend .containerInputUpdateItem;
  // width: 5rem;
  width: 100%;

  &:focus {
    border-color: #c5c5c5;
    // outline: 0;
    box-shadow: 0 0 0 0.20rem #83838340;
  }
}

//--------------------------------- Corkage percentage end ----------------------------------------//

//---------------------------------- Filter menu init---------------------------------------------------
.containerInputUpdateItemFilter {
  @extend .containerInputUpdateItem;
  width: 8rem;

  &:focus {
    border-color: #c5c5c5;
    // outline: 0;
    box-shadow: 0 0 0 0.20rem #83838340;
  }
}


//---------------------------------- Filter menu end --------------------------------------------------


//--------------------------------- Order manager-provider  init ----------------------------------------//

.containerTopPanelAndAccordion {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2rem;
  // gap: 2rem;
  text-align-last: left;
}

.breadCrumbAndBtnOrders {
  display: flex;
  flex-direction: column;
  width: 100%;
}




//--------------------------------- Order manager-provider  end ----------------------------------------//

//--------------------------------- Add item  init ----------------------------------------//
// Nav Add item manager provider

.newItemManagProvider {
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2rem;
  gap: 2rem;
  text-align-last: left;
}

.headerCards {
  color: $dashboard-color-textPrimary ;
  font-weight: 700;
  font-size: 0.9rem;
  background-color: #F2F2F2 !important;
  padding: 1rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;

  &:hover {
    color: $dashboard-color-textPrimary ;
  }
}

.addItemCardBody {
  padding: 0px;
  background-color: $dashboard-color-white;
  border-radius: 10px;
  box-shadow: 0px 2px 5px 4px rgba(0, 0, 0, 6%);
  // min-width: 347px;
}

.addItemFormContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 3rem;
  gap: 2.5rem;

  p {
    // max-width: 336px;
    text-align: center;
    font-size: 0.8rem;
    font-weight: 600;
    color: #414141;
    line-height: 1rem;
    margin-bottom: 0px;
  }
}

.addItemInputGroup {
  display: flex;
  flex-direction: column;
  // align-items: center;
  gap: 0.5rem;
}

.addItemSearch {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;

  h4 {
    color: #414141;
    font-size: 0.8rem;
  }

  p {
    text-align: center;
    font-size: 0.8rem;
    font-weight: 600;
    color: #EF4A49;
    line-height: 1rem;
    margin-bottom: 0px;
  }

  a {
    font-size: 0.73rem;
  }
}

.addItembtnScanner {
  width: 100%;
}

.containerAddItemCol {
  display: flex;
  gap: 0.5rem;
  flex: 1 0 0%;
  // line-height: 1rem;
}

.containerAddItem {
  border: 0.1rem solid #d4d4d4;
  border-radius: 10px;
  font-size: 0.7rem;
  letter-spacing: 0.02rem;

  &:focus {
    border: 1px solid #3A82EF;
  }
}


.addItemlabel {
  @extend .addItemLabelForm;
}



.addItemLabelFormT {
  min-width: 106px;
}



.addItemmandatory {
  color: $dashboard-color-primary;
}

.addItemLabelForm {
  font-family: Montserrat;
  margin: 0.5px;
  font-size: 0.8rem;
  font-weight: 600;
  color: $dashboard-color-textPrimary;
  line-height: 0.9rem;
}

.btnAddItemSave {
  @extend .btnModalSave;
  background-color: $dashboard-color-approvalPrimary !important;

  &:hover {
    background-color: $dashboard-color-approvalSecondary !important;
  }
}

.div-btnModalSave-all {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.btnModalSave-all {
  padding: 0px;
  font-size: 0.813rem;
  font-weight: 500;
  height: 31px;
  width: 123px;
  color: white;
  background-color: #09B397;
  border-radius: 5px;

  &:hover,
  &:focus {
    color: $dashboard-color-white ;
    font-weight: 500;
    background-color: $dashboard-color-approvalSecondary ;
    box-shadow: 0 0 0 0.20rem rgba(0, 196, 104, 0.3);
  }
}

.btnAddItemSearch {
  display: flex;
  justify-content: center !important;
  align-items: center;
  padding: 0rem 0.6rem 0rem 0.6rem;
  background-color: #F1ECDE;
  border-radius: 5px;
  color: #414141;
  font-size: 14px;
  height: 41px !important;
  width: 41px !important;
  align-self: flex-end;
  margin-bottom: 0.3rem;

  &:hover {
    background-color: #e7e1d3 !important;
    color: #414141;
    font-weight: 600;
    box-shadow: 0 0 0 0.20rem rgba(172, 150, 115, 0.3);
  }
}


// -------------------------No encontrado---------------------
.itemTablePagination {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

// -------------------------No encontrado---------------------

.photoMinion {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgb(242 244 245);
  border: 1px dashed #c0c0c0;
  border-radius: 10px;
  width: 100%;
  height: 250px;
  margin: 0 auto;

  img {
    object-fit: contain;
    width: 100%;
    height: 60%;
  }
}

.custom-file {
  width: 40%;
  border-radius: 10px;
  color: #accdff;
}

.custom-file-label {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0rem 0rem 0rem;
  font-size: 0.8rem;
  color: #414141;
  height: 50px;
  width: 177px;
  border-radius: 5px;
}


.fieldRequireMsg {
  font-weight: 500;
  font-size: 0.6rem;
  color: $dashboard-color-disapprovalPrimary;
}

.addItemBtnUploadAndCapture {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 1rem;
}



//--------------------------------- Add item  end ----------------------------------------//



//--------------------------------- Data Entry init --------------------------------//

//este maneja el ancho de la tabla, customizar para diferentes vistas
.sheetItemTableData {
  height: 10%;
  // height: 62vh;
  background-color: white;
  box-shadow: 0px 2px 5px 4px rgba(0, 0, 0, 6%);
  border-radius: 0.6rem;
  scroll-behavior: smooth;
}

//-----------------------------------------------------------
.itemTableAndPagination {
  width: 100%;
  overflow: scroll;
}

.itemTableAndPagination-center {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;

}

//Sheet ---------------------

.rwdTableDataEntry {
  background-color: $dashboard-color-white;
  border-radius: 10px;
  box-shadow: 0px 2px 5px 4px rgba(0, 0, 0, 6%);
}

.theadTableDataEntryMobile {
  @extend .theadHeaderMobile !optional;
  background-color: #F2F3F5;
  border: 0.5px solid #F2F3F5;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.theadTableDataEntry {
  @extend .theadUpdate !optional;
  background-color: #F2F3F5;
}

.titleTableDataEntry {
  color: $dashboard-color-textPrimary !important;
  font-weight: 600;
  font-size: 14px;
}

.trTableDataEntry {
  border-top: none !important;
  border-color: none !important;

  &:first-child {
    border-bottom: 1px solid white;
  }

  &:last-child {
    border-bottom: 1px solid white;
  }
}

//Badge----------------------------------
//btn sheet------------------------------

.containerImgAndBtnItemDataEntry {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;


  img {
    width: 30px;
    height: 100%;
  }

  form input {
    border-radius: 10px;
    height: 27px;
    margin-bottom: 1rem;

  }

  .containerImgDataMinion {
    display: flex;
    gap: 1rem;

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      border: dashed 1px gray;
      border-radius: 10px;
      padding: 0.5rem;
      width: 8rem;

      h4 {
        font-size: 0.7rem;
        color: #414141
      }
    }
  }


}

.btnsItemTableData {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.btnToCureContent {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: #3A82EF;
  background-color: #E7F3FF;
  width: 100%;
  height: 41px;

  &:hover {
    background-color: #D2E8FF;
    color: #3A82EF;
  }

  &:focus {
    // border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
  }
}


//modal----------------------------

.modalBodyData {
  padding-bottom: 0px !important;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.headermodalFormData {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $dashboard-color-primary;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.headerTextmodalFormData {
  display: flex;
  justify-content: center;
  flex-grow: 2;
  gap: 0.5rem;
}

.textFormData {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  margin: 0px;
  color: $dashboard-color-white;
}

.containerTitlesFormData {
  display: flex;
  justify-content: center;
  background-color: #F0F2F5;
  border-radius: 10px;
  padding: 1rem;
}

.titlesFormData {
  color: $dashboard-color-primary;
  font-size: 17px;
  font-weight: bolder;
}

.containerFormDataBlock {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.containerInputData {
  border: 0.1rem solid #d4d4d4;
  border-radius: 0.75rem;
  height: 41px;
  font-size: 0.75rem;
  color: #737373;
  letter-spacing: 0.01rem;


  &:focus {
    border-color: #86b7fe !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%) !important;
    z-index: inherit !important;
  }
}

.certificationData {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;
  padding: 1.5rem;
}

.containerFormData {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.containerFormDataRow {
  display: flex;
  flex-direction: column;
  gap: 1rem;

}

.containerDataCol {
  flex: 1 0 0%;
  line-height: 1.5rem;
}

.roundedBackRef {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10px;
  height: 10px;
  background-color: #68CF29;
  border-radius: 0.5rem;
}

.refAccOrders {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.refColorAccOrders {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

//Modal buttons --------------------------

// Save ------------------
.btnModalSave {
  padding: 0px;
  font-weight: 500;
  height: 41px;
  width: 150px;
  color: $dashboard-color-white ;
  background-color: $dashboard-color-approvalPrimary ;
  border-radius: 5px;

  &:hover,
  &:focus {
    color: $dashboard-color-white ;
    font-weight: 500;
    background-color: $dashboard-color-approvalSecondary ;
    box-shadow: 0 0 0 0.20rem rgba(0, 196, 104, 0.3);
  }
}

.btnModalSave-size {
  height: 2rem;
  width: 6rem !important;
}


// Cancel ------------------
.btnModalCancel {
  padding: 0px;
  font-weight: 500;
  height: 41px;
  width: 100%;
  // color: $dashboard-color-textPrimary ;
  // background-color: #dddfe2;
  color: $dashboard-color-white ;
  background-color: $dashboard-color-primary;

  border: none;
  border-radius: 5px;

  &:hover,
  &:focus {
    font-weight: 500;
    // color: $dashboard-color-textPrimary ;
    // background-color: #c4c6c8;
    color: $dashboard-color-white ;
    background-color: #d81613;
  }
}

.modalFooterContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border-top: none;
}


//--------------------------------- Data Entry end --------------------------------//

// --------------------------------- Data entry winery init ------------------------------//

.btnToCureContentWinery {
  @extend .btnToCureContent !optional;
}

// <!-- .table-tabs .nav-tabs .nav-link.active {

// --------------------------------- Data entry winery end --------------------------//
// //---------------------------------404 init---------------------//

.img-404 {
  width: 20rem;
}

// //---------------------------------404 end---------------------//
//---------------------------------- Invite people init --------------------------//

// .inviteUserTitle {
//   display: flex;
//   flex-direction: column;
//   align-items: start;
//   justify-content: space-between;
//   width: 100%;
//   margin-bottom: 2rem;
//   gap: 2rem;
//   text-align-last: left;
// }

// .cardInviteContainer {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   padding: 2rem 2rem 3rem;
//   gap: 1rem;
// }

// .formInvitePeople {
//   display: flex;
//   flex-direction: column;
//   width: 100%;
//   gap: 1rem;
// }


// .invitePeopleNameSurn {
//   display: flex;
//   flex-direction: column;
//   width: 100%;
//   gap: 1rem;
// }

// .invitePeople {
//   display: flex;
//   flex-direction: row;
//   gap: 0.5rem;
// }

// .invitePeopleWarning {
//   display: flex;
//   gap: 0.5rem;
//   justify-content: center;

//   p {
//     display: flex;
//     align-items: center;
//     color: #797979 !important;
//     margin-bottom: 0px;
//     font-size: 0.7rem;
//     line-height: 0.8rem;
//     font-weight: 500;
//     font-family: 'Montserrat';
//     background-color: #fdf9ed;
//     padding: 0.3rem;
//     border-radius: 0.3rem;
//   }
// }

.form-select-vlc {
  font-size: 0.75rem;
  color: #737373;
  letter-spacing: 0.01rem;
}


// .btnInvitePeople {
//   @extend .btnModalSave !optional;
//   height: 44px;
//   width: 100% !important;
//   margin-top: 3px;
// }

//---------------------------------- Invite people end --------------------------//
//---------------------------------- Change rol init --------------------------//


.changeRolNameSurn {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
}


.cardChangeRolContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 2rem 3rem;
  gap: 3rem;
}

.formChangeRol {
  display: flex;
  flex-direction: column;
  width: 100%;
  // gap: 0.5rem;
}

.changeRolWarning {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
  
    p {
      display: flex;
      align-items: center;
      color: #797979 !important;
      margin-bottom: 0px;
      font-size: 0.7rem;
      line-height: 0.8rem;
      font-weight: 500;
      font-family: 'Montserrat';
      background-color: #fdf9ed;
      padding: 0.3rem;
      border-radius: 0.3rem;
    }

  img {
    width: 4.5rem;
  }

  p {
    display: flex;
    align-items: center;
    color: $dashboard-color-textPrimary !important;
    margin-bottom: 0px;
    line-height: 0.8rem;
    font-weight: bolder;
    font-size: 14px;
  }
}

.btnchangeRol {
  @extend .btnModalSave !optional;
  width: 100%;

}

//---------------------------------- change rol end --------------------------//


//---------------------------------- Login User Pass init -------------------------//

// .backLoginUserPass {
//   background: linear-gradient(0deg, #EF4A49, #F0B0A6);
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   overflow: hidden;
// }

// .cardLoginUserPass {
//   display: flex;
//   justify-content: center;
//   align-content: center;
//   min-width: 300px;
//   width: 400px;
//   height: fit-content;
//   padding: 1rem;
//   margin: 1rem;
// }

// .googleIcon {
//   width: 10% !important;
//   margin-bottom: 0rem !important;
//   margin-right: 0.5rem !important;
// }

// .btnGoogle {
//   width: 100%;
//   border: #fff;
//   border-radius: 0.4rem !important;
//   background-color: white;
//   box-shadow: 1px 0px 13px 0px rgb(82 63 105 / 20%) !important;
//   background-position: 86px 13px !important;
//   height: 55px;
//   padding-left: 12px !important;

// }

// .formLoginUserPass {
//   display: flex;
//   flex-direction: column;
//   gap: 1rem;
//   width: 100%;
//   align-items: center;
//   justify-content: center;
//   height: auto;

//   input {
//     width: 100%;
//   }

// }

// .btnSignIn {
//   border-radius: 5px;
//   width: 100%;
//   height: 45px;
//   font-family: 'Montserrat';
// }

// .formInputImageEnvel {
//   &::placeholder {
//     padding-left: 30px;
//     background-image: url('/../../assets/images/adm-panel/envelop.svg');
//     background-size: 17px 17px;
//     background-repeat: no-repeat;
//     background-position: left center;
//     color: #BABABA;
//   }
// }


// .loginUserAlert {
//   display: flex;
//   justify-content: center;
//   color: #EF4B48;
//   padding: 0rem 1rem;
//   font-size: 0.8rem;
//   line-height: 0.8rem;
//   gap: 0.5rem;
//   font-family: 'Montserrat';
// }


//---------------------------------- Login User Pass end --------------------------//


//---------------------------------- Register bussiness form init -------------------------//

 .btnRegister-next {
   @extend .btnModalSave !optional;
   font-family: 'Montserrat';
   color: $dashboard-color-white ;
   background-color: #3A82EF;

   &:hover,
   &:focus {
     color: $dashboard-color-white ;
     background-color: #0a67f3;
     box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);

   }
 }

 .btnRegister-back {
   @extend .btnModalSave !optional;
   font-family: 'Montserrat';
   color: $dashboard-color-white ;
   background-color: #87b1f1;

   &:hover,
   &:focus {
     color: $dashboard-color-white ;
     background-color: rgb(82, 147, 243);
     box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);

   }
 }

// .mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
//   padding: 0 0em !important;
// }

// .matOpacity {
//   opacity: 0.7;
// }

//---------------------------------- Register bussiness form end -------------------------//

//--------------------------------- New item init ----------------------------------------//


.mat-form-field {
  mat {
    margin-bottom: 0px !important;
    padding-top: 0px !important;
  }

}

.table-tabs .nav-tabs {
  border: 0;
}

//--------------------------------- New item end ----------------------------------------//



//--------------------------------- Media queries init -----------------------------------------//


@media only screen and (max-width: 3500px) {

  //------------------- <=3500
  // Marca -------------------------
  .brand-logo-vlc {
    padding: 20px !important;
  }

  .logo-abbr-vlc {
    min-width: 60px !important;
    width: 60px !important;
    height: 60px !important;
  }

  .brand-title-vlc {
    display: flex;
    justify-items: center;
    margin-top: 0px;
    margin-left: 20px;
  }

  .logo-abbr-vlc-text {
    min-width: 90px !important;
    width: 90px !important;
    height: 90px !important;
  }

  .badge-vlc {
    margin-top: 0px;
    margin-bottom: 0px;
  }



  .badge-vlc-pending {
    margin-top: 0px;
    margin-bottom: 0px;

  }


  // Marca end -------------------------

  .middle {
    text-align: left;
    color: $dashboard-color-textPrimary ;
  }
}

// @media only screen and (max-width: 1800px) {}

// @media only screen and (max-width: 1350px) {}

// @media only screen and (max-width: 1200px) {}

@media only screen and (max-width: 1400px) {


  .containerUpdateItemSelect {
    height: 41px !important;
  }

}

@media only screen and (max-width: 1198px) {

  // Marca -------------------------
  .brand-title-vlc {
    display: flex;
    justify-items: center;
    margin-top: 0px;
    margin-left: 10px;
  }

  .brand-title-vlc {
    display: flex;
    justify-items: center;
    margin-top: 0px;
    margin-left: 10px;
  }

  .logo-abbr-vlc-text {
    min-width: 80px !important;
    width: 80px !important;
    height: 80px !important;
  }

  // Marca end -------------------------

  .row-vlc {
    flex-direction: column !important;
    flex-wrap: nowrap !important;
  }

}

// @media (min-width: 575px) {

//   .btnHeaderAccordion {
//     display: flex;
//     flex-direction: row;
//   }
// }

@media only screen and (max-width: 1023px) {
  //------------------- <=1023

  .nav-header-vlc {
    width: 80px;
  }

  .brand-logo-vlc {
    padding-left: 0px !important;
    padding-right: 0px !important;
    justify-content: center !important;
  }

}

.grid-col--vlc {
  flex-wrap: nowrap !important;
}

// ----------------------------------------------------------------------------------------

@media only screen and (max-width: 575px) {

  .btnAddUpdateItem,
  .btnAddDeleteItem,
  .containerInputUpdateItem {
    width: 100%;
    max-width: 100%;
  }

  // .container-form-suggestion {
  //   flex-direction: column-reverse;
  //   justify-content: flex-end;
  //   height: 90vh;
  //   // justify-content: flex-start;
  // }

  // .title-right {
  //   font-size: 1rem;
  // }

  // .figure-right-suggestion {
  //   width: 10rem;
  // }

  // .container-right-suggestion {
  //   width: 100%;
  // }

  // .container-left-suggestion {
  //   width: 100%;
  //   height: 30rem;
  // }

  .updateItemInputEanAndBtn {
    display: none;
  }

  .updateItemEanScan {
    display: contents;
  }

  .addItemFormEan {
    display: none;
  }

  .rwd-table td:before {
    content: none;
    // attr(data-th) ": "
  }

  .trTableDataEntry {
    padding-right: 0px;
  }

}

// ----------------------------------------------------------------------------------------
@media (min-width: 1600px) {
  .img-itemUpdate {
    max-width: 20%;
  }
}

// ----------------------------------------------------------------------------------------
@media (min-width: 1401px) {



  .btn-primary-qrs {
    margin-bottom: 5px;
  }

  .btnSignIn {
    font-size: 0.813rem;

  }


  .containerInputUpdateItem,
  .containerInputUpdateItemCork,
  .containerInputData,
  .containerInputDataLogin,
  .containerAddItem,
  .btnUpdateItemSearch,
  .btn-primary-qrs,
  .btnModalSave,
  .btnToCureContent {
    height: 41px;
    font-size: 0.813rem;
    font-weight: 500;
  }

  .accorBtnCloseItem,
  .accorBtnConfirmItem {
    height: 22px !important;
  }

  // Add Item--------------------------------
  .addItemFormGroup {
    width: 50% !important;
  }

  .btnUpdateItemNewItemTable {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .itemTableBoxSearch {
    flex-direction: row;
    width: 100%;

  }
}

@media (min-width: 1200px) {

  .qrGeneratorHeaderB {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1.5rem;
    margin-bottom: 3rem;
    align-items: center;

    .containerFormDataRowQr {
      width: 60%;
    }

  }

  .orderStatusTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 2rem;
  }


}

@media (min-width: 550px) {
  .theadHeaderMobile {
    display: none;
  }
}

@media (min-width: 992px) {




  .addItemLabelForm {
    font-size: 0.875rem;
  }


  .btn-primary-qrs,
  .btnToCureContentWinery
   {
    height: 48px !important;

  }
  // .btnInvitePeople

  .btnAddItemSearch {
    height: 48px !important;
    width: 48px !important;
  }
}




// ----------------------------------------------------------------------------------------

@media (min-width: 767px) {





  .contentBodyCards {
    display: flex;
    flex-direction: row;
  }



  .corkageInputandText {
    display: flex;
    flex-direction: row !important;
  }

  .massiveHeightInput {
    width: 40% !important;
  }

  .addItemFormContainer {
    width: 650px;
  }

  // .btnInvitePeople {
  //   width: 400px !important;
  // }


  .schedule-inputs {
    flex-direction: row !important;
    align-items: end;

    // .containerHoursSchedule {
    //   flex-direction: row;

    // }

    .textInputSchedule {
      flex-direction: row;

    }

  }


  .invitePeopleNameSurn {
    flex-direction: row;
    align-items: end;
  }



  .itemTableBoxSearchNoTitle {
    flex-direction: row;
  }

  .itemTablePercentBoxAndWarning {
    flex-direction: row;
  }

  .accCardAndbtn {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .btnOrders {
    display: flex;
    flex-direction: column !important;
    margin-top: 2rem;
  }

  .containerbtnAccMesa {
    max-width: 400px;
    height: 41px;
  }
}


// ----------------------------------------------------------------------------------------
@media (min-width: 575px) {

  .containerRestoProvOrders {
    flex-direction: row;
   
  }

  .breadCrumbAndBtnOrders {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }


  .newItemManagProvider {
    align-items: center;
    flex-direction: row;
  }


  .containerFormDataRowQr {
    display: flex;
    flex-direction: row;
  }

  .btnChooseBranch {
    width: 100% !important;
  }


  .addItembtnScanner {
    visibility: hidden;
    display: none;
  }

  .changeRolNameSurn {
    flex-direction: row;
  }

  .schedule-inputs {
    flex-direction: column;

    // .containerHoursSchedule {
    //   flex-direction: row;
    // }

    .textInputSchedule {
      flex-direction: row;
    }
  }

  .topPanelFiltersAdmFoodFliter {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: start;
    gap: 0.7rem;
    margin-bottom: 0.8rem;
  }

  .topPanelFiltersAdm,
  .topPanelFiltersMat {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 0.7rem;
  }

  .topPanelFiltersAdm {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
  }

  .topPanelFiltersAdm {
    margin-bottom: 0.8rem;
  }


  // .topPanelFiltersMat {
  //   flex-direction: row;
  // }

  .topPanelFilters {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 0.7rem;
  }

  .itemTablePercentajeContent {
    align-items: flex-start;
  }

  .itemTablePerBoxAndBtn {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }

  .itemsAndBtn {
    display: flex;
    flex-direction: row;
  }

  .btnOrders {
    flex-direction: row;
  }

  .containerbtnAccMesa {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .containerBtnAccOrders {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .containerBtnAccOrdersWine {
    display: flex;
    flex-direction: column-reverse;
    gap: 1rem;
    // padding: 0.7rem;
  }

  .accCardAndbtnWine {
    display: flex;
    flex-direction: row;
    padding: 1rem;
    background: #E6E6E6 !important;
    border-radius: 0.2rem;
    gap: 1rem;
  }

  .textAccTotalMesa {
    display: flex;
    justify-content: center;

  }

  .containerFormDataRow {
    display: flex;
    flex-direction: row;
    max-width: 100%;
    gap: 1rem;
  }

  .containerFormDataBlock {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .topPanelContainerDAta,
  .topPanelItemSearchProduct,
  .itemTableBoxSearchLiquid {
    display: flex;
    flex-direction: row;
  }

  .itemTableBoxSearchLiquid,
  .topPanelContainerDAta {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }



  .topPanelItemEan {
    width: 100% !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .topPanelNoCure {
    display: flex;
    align-items: center;

    .form-check label {
      font-size: 0.85rem;
      font-weight: 600;
      color: #414141;
    }
  }


  .topPanelContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }


  .btnToCureContent {
    display: flex;
    justify-content: center;
    width: 93px;
  }

  .btnToCureContentWinery {
    width: 180px;
  }



  .tdTableData {
    padding-left: 0.5rem !important;
  }


  .tdTableDataBtn {
    padding-right: 1rem !important;
  }

  //Modals

  .btnModalSave {
    width: 150px;
  }

  .btnModalCancel {
    width: 150px;

  }

  // Add Item--------------------------------

  .addItemContStockAndPrice {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 1rem !important;
  }

  .btnAddItemSave {
    font-size: 0.813rem;
  }

  // Corkage -------------------------------
  .cardBtnCancelAndSAve {
    display: flex;
    flex-direction: row;
  }
}




//--------------------------------- Media queries ends -----------------------------------------//


//------------ Template breackpoints ------------------------------


// @mixin respond($breakpoint) {

//   @if($breakpoint == "phone") {

//     @media only screen and (max-width: 575px) { @content; }
//     }

//     @if($breakpoint == "phone-land") {

//         @media only screen and (max-width: 767px) { @content; }
//     }

//   @if($breakpoint == "tab-port") {

//     @media only screen and (max-width: 991px) { @content; }
//   }

//   @if($breakpoint == "tab-land") {

//     @media only screen and (max-width: 1199px) { @content; }
//     }

//   @if ($breakpoint == "laptop") {
//         @media only screen and (max-width: 1400px) { @content; }
//     }

//     @if ($breakpoint == "desktop") {

//         @media only screen and (min-width: 1200px) { @content; }
//     }

//   @if($breakpoint == "big-desktop") {

//     @media only screen and (min-width: 1800px) { @content; }
//   }

// }
@media (min-width: 550px) {


  .theadTableDataEntryMobile {
    display: none !important;
    visibility: hidden !important;
  }

  .containerInputUpdateItem {
    border: 0.1rem solid #d4d4d4;
    border-radius: 10px;
    padding: .5rem;
    display: flex;
    justify-content: center;
    margin-right: 0rem;
  }


  #priceUpdateItem {
    width: auto;
  }

  // #qtyUpdateItem{
  //   width: .7rem;
  // }
  #tdPrice {
    width: 15%;

  }

  .categoryUpdateItem {
    display: block;
  }

  .tdFlexAction {
    justify-content: none;
  }

  .theadUpdateMobile {
    display: none;
  }

  .tdFlex {
    display: revert-layer;
  }

  .img-itemUpdate {
    display: block;
    max-width: 30%;
  }


  .rwd-table td:before {
    display: none;
  }

  .rwd-table th,
  .rwd-table td {
    display: table-cell;
    // width: 100%;
    padding: 0.25em 0.5em;
  }

  // .rwd-table th:first-child,
  // .rwd-table td:first-child {
  //   padding-left: 0;
  // }

  // .rwd-table th:last-child,
  // .rwd-table td:last-child {
  //   padding-right: 0;
  // }

  .rwd-table th,
  .rwd-table td {
    padding: 1em;
  }
}